import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/OVOM.css';
import OurMission from "../img/OurMission.png";
import OurVision from "../img/OurVision.png";
import OurValue from "../img/OurValue.png";

const OVOM = () => {
  const ConsoleText = ({ words = ['Shaping the World', 'With Bizkranti IT Innovation'], colors = ['#caca0f', '#f7f5b6'] }) => {
    const [displayText, setDisplayText] = useState('');
    const [underscoreVisible, setUnderscoreVisible] = useState(true);
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [letterCount, setLetterCount] = useState(0);
    const [direction, setDirection] = useState(1); // 1 for forward, -1 for backward

    useEffect(() => {
      const intervalId = setInterval(() => {
        const currentWord = words[currentWordIndex];

        if (letterCount === currentWord.length + 1 && direction === 1) {
          setDirection(-1);
        } else if (letterCount === 0 && direction === -1) {
          setDirection(1);
          setCurrentWordIndex((currentWordIndex + 1) % words.length);
        } else {
          setDisplayText(currentWord.substring(0, letterCount));
          setLetterCount(letterCount + direction);
        }
      }, 120);

      return () => clearInterval(intervalId);
    }, [letterCount, direction, currentWordIndex, words]);

    useEffect(() => {
      const underscoreInterval = setInterval(() => {
        setUnderscoreVisible(prev => !prev);
      }, 400);

      return () => clearInterval(underscoreInterval);
    }, []);

    return (
      <div className="console-container">
        <h1 id="text" style={{ color: colors[currentWordIndex] }}>
          {displayText}
          {underscoreVisible && '|'}
        </h1>
      </div>
    );
  };

  const { ref: cardRef, inView: cardInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  window.addEventListener('load', () => {
    const visionCards = document.querySelectorAll('.VISION-card');
    visionCards.forEach(card => {
      card.classList.add('show-before-after');
    });
  });


  return (
    <div className="VISION-container container my-5 p-4">
      <div className="text-center mb-4">
        <h1 className="text-dark">
          <ConsoleText />
        </h1>
      </div>
      <div className="VISION-row">
        <div
          ref={cardRef}
          className={`VISION-card container col-md-4 ${cardInView ? 'animate-in' : 'animate-out'}`}
        >
          <div className={`VISION-content ${cardInView ? 'OVOM-fade-in' : ''}`}>
            <div className="d-flex flex-column align-items-center">
              <div className="VISION-img-container">
                <img src={OurVision} alt="Our Vision" className="VISION-img" />
              </div>
              <div className="VISION-text">
                <h4 className="fw-bold" style={{ color: '#f7f5b6' }}>Our Vision</h4>
              </div>
            </div>
            <p className="OVOM-text text-left mt-3">
              Our goal is to become a global leader in the information technology industry by empowering our clients to harness technology to solve their toughest challenges.
              As a top digital marketing company in India, we aim to expand our reach by introducing transformative digital marketing services to new markets.
              We will continue to be a key driver of value and a crucial investment for businesses in this sector.
            </p>
          </div>
        </div>
        <div
          ref={cardRef}
          className={`VISION-card container col-md-4 ${cardInView ? 'animate-in' : 'animate-out'}`}
        >
          <div className={`VISION-content ${cardInView ? 'OVOM-fade-in' : ''}`}>
            <div className="d-flex flex-column align-items-center">
              <div className="VISION-img-container">
                <img src={OurMission} alt="Our Mission" className="VISION-imgMission" />
              </div>
              <div className="VISION-text">
                <h4 className="fw-bold" style={{ color: '#f7f5b6' }}>Our Mission</h4>
              </div>
            </div>
            <p className="OVOM-textMission text-left" >
              The objective behind starting Bizkranti IT Innovations Pvt Ltd is to introduce the digital world and provide better facilities to every small and big enterprise in India by making website development, application development, and digital marketing easy and affordable, creating a digital revolution.
            </p>
          </div>
        </div>
        <div
          ref={cardRef}
          className={`VISION-card container col-md-4 ${cardInView ? 'animate-in' : 'animate-out'}`}
        >
          <div className={`VISION-content ${cardInView ? 'OVOM-fade-in' : ''}`}>
            <div className="d-flex flex-column align-items-center">
              <div className="VISION-img-container">
                <img src={OurValue} alt="Our Values" className="VISION-img" />
              </div>
              <div className="VISION-text">
                <h4 className="fw-bold" style={{ color: '#f7f5b6' }}>Our Values</h4>
              </div>
            </div>
            <p className="OVOM-text text-left  mt-3">
              Our values are centered on innovation, excellence. As a leading digital marketing company in India, we are committed to delivering  digital marketing services with integrity and transparency, fostering strong partnerships built on trust. Collaboration and adaptability drive our approach, ensuring we meet the evolving needs of our clients. We prioritize sustainability and continuous growth, striving to make a positive impact on the environment and society while remaining at the forefront of the IT industry.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OVOM;
