// import React, { useState } from 'react';
// import { useNavigate

//  } from 'react-router-dom';
// // Importing Images
// import DigitalRemovebg from '../img/servicesIMG/DigitalMarketing/DigitalRemovebg.png';
// import BrandAwareness from '../img/servicesIMG/DigitalMarketing/BrandAwareness.png';
// import CostEffective from '../img/servicesIMG/DigitalMarketing/CostEffective.png';
// import LeadGeneration from '../img/servicesIMG/DigitalMarketing/LeadGeneration.png';
// import MarketingAutomation from '../img/servicesIMG/DigitalMarketing/MarketingAutomation.png';
// import MeasuringResult from '../img/servicesIMG/DigitalMarketing/MeasuringResult.png';
// import TargetReach from '../img/servicesIMG/DigitalMarketing/TargetReach.png';

// import CallToAction from './CallToAction';
// import Footer from './Footer';

// import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import '../css/DigitalMarketing.css';
// const Digitalmarketing = () => {

//     const navigate = useNavigate();
//     const Click = () => {
//       navigate('/Contact');
//     }

//     const [selectedService, setSelectedService] = useState(null);
//     const [activeFAQ, setActiveFAQ] = useState(null);

//     const shoDMetails = (service) => {
//         setSelectedService(service);
//     };

//     const hideDetails = () => {
//         setSelectedService(null);
//     };

//     const toggleFAQ = (index) => {
//         setActiveFAQ(activeFAQ === index ? null : index);
//     };

//     return (
//         <>
//             <div>
//                 <section className="DM-title-section text-center py-5">
//                     <div className="DM-container container">
//                         <h1 class="text-center">Digital <span style={{ color: '#fcc101'}}>Marketing</span></h1>
//                         <p class="text-center" style={{ color: 'black' }}>
//                         At BizKranti, we provide comprehensive Digtal marketing solutions to bring your vision to life.
//                         </p>
//                     </div>
//                 </section>

//                 <section className="DM-services" id="services">
//                 <h2 class="text-center fs-1"><b> Our <span style={{ color: '#fcc101'}}>Services</span></b></h2>
//                     <div className="DM-container-fluid d-flex align-items-center justify-content-between py-5">
//                         <div className="DM-services-content">
//                             <div className="DM-services-image">
//                                 <img src={DigitalRemovebg} alt=" Isometric illustration of diverse individuals working together on a laptop, focused on digital marketing efforts and collaboration." />
//                             </div>
//                             <div className="DM-service-boxes">
//                                 <div className="DM-service-box zoom-in" onClick={() => shoDMetails('BrandAwareness')}>
//                                     <div className="DM-services-image1">
//                                         <img src={BrandAwareness} alt="A black background showcases a white clock, illustrating the vital relationship between time management and brand awareness." />
//                                     </div>
//                                     <h3 className='DM-service-boxes-title fs-4'>BrandAwareness</h3>
//                                     <p>Crafting beautiful and responsive websites.</p>
//                                 </div>
//                                 <div className="DM-service-box zoom-in" onClick={() => shoDMetails('CostEffective')}>
//                                     <div className="DM-Graphic">
//                                         <img src={CostEffective} alt=" A sleek white clock on a black background, representing a stylish and cost-effective solution for timekeeping." />
//                                     </div>
//                                     <h3 className='DM-service-boxes-title fs-4'>CostEffective</h3>
//                                     <p>Building robust online stores.</p>
//                                 </div>
//                                 <div className="DM-service-box zoom-in" onClick={() => shoDMetails('LeadGeneration')}>
//                                     <div className="DM-packaging">
//                                         <img src={LeadGeneration} alt=" A minimalist white clock set against a black backdrop, illustrating the significance of time management in lead generation." />
//                                     </div>
//                                     <h3 className='DM-service-boxes-title fs-4'>LeadGeneration</h3>
//                                     <p>Boost your website's visibility on search engines.</p>
//                                 </div>
//                                 <div className="DM-service-box zoom-in" onClick={() => shoDMetails('MarketingAutomation')}>
//                                     <div className="DM-Marketing">
//                                         <img src={MarketingAutomation} alt="A striking white clock set against a black backdrop, illustrating the importance of timing in marketing automation efforts." />
//                                     </div>
//                                     <h3 className='DM-service-boxes-title fs-4'>Marketing Automation</h3>
//                                     <p>Building robust online stores.</p>
//                                 </div>
//                                 <div className="DM-service-box zoom-in" onClick={() => shoDMetails('MeasuringResult')}>
//                                     <div className="DM-Web">
//                                         <img src={MeasuringResult} alt="A visually appealing image of a white clock on a black background, highlighting the essence of timing in marketing success." />
//                                     </div>
//                                     <h3 className='DM-service-boxes-title fs-4'>MeasuringResult</h3>
//                                     <p>Building robust online stores.</p>
//                                 </div>
//                                 <div className="DM-service-box zoom-in" onClick={() => shoDMetails('TargetReach')}>
//                                     <div className="DM-rebranding">
//                                         <img src={TargetReach} alt="A minimalist design featuring a white clock on a black background, creating a bold and elegant visual representation of time." />
//                                     </div>
//                                     <h3 className='DM-service-boxes-title fs-4'>TargetReach</h3>
//                                     <p>Building robust online stores.</p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </section>

//                 {selectedService && (
//                     <section className="DM-details" id="details">
//                         <div className="DM-details-content show">
//                             <h2 id="details-title">{selectedService}</h2>
//                             <p id="details-description">
//                                 {selectedService === 'TargetReach' && 'Focus on identifying and reaching the right audience for your product or service. Use data-driven strategies to ensure your marketing efforts are directed at the most relevant segments, maximizing impact and engagement.'}
//                                 {selectedService === 'MarketingAutomation' && 'Use software to automate repetitive marketing tasks, such as email campaigns, social media posting, and lead nurturing. This streamlines processes, saves time, and ensures consistent communication with your audience.'}
//                                 {selectedService === 'MeasuringResult' && 'Implement tools and metrics to track the success of your marketing campaigns. Analyze key performance indicators (KPIs) like conversion rates, customer acquisition costs, and return on investment (ROI) to assess effectiveness and make data-driven decisions.'}
//                                 {selectedService === 'LeadGeneration' && ' Develop strategies to attract and capture potential customers interest, turning them into leads. Use content marketing, social media, and SEO to drive traffic, and employ landing pages and forms to gather contact information.'}
//                                 {selectedService === 'CostEffective' && 'Optimize your marketing budget by focusing on high-impact, low-cost strategies. Use digital marketing techniques like social media marketing, content creation, and email campaigns to reach a broad audience without significant expenses.'}
//                                 {selectedService === 'BrandAwareness' && 'Build and maintain a strong brand presence in the market. Use consistent messaging, visual identity, and storytelling to establish your brand and make it recognizable. Engage in activities that increase visibility, such as social media, content marketing, and public relations.'}
//                             </p>
//                             <button className="bt1" onClick={hideDetails}>Close</button>
//                             <button className="bt2" onClick={Click}>Enquire Us</button>
//                         </div>
//                     </section>
//                 )}

//                 <section className="DM-FAQ" id="FAQ">
//                     <div className="DM-faq-footer">
//                         <h2 className="DM-faq-header" style={{ color: 'black' }}><b>Frequently <span style={{ color: '#fcc101' }}>Asked Questions</span></b></h2>

//                         {[
//                             { question: 'What is Digital Marketing, and why is it important?', answer: 'Digital Marketing refers to the use of online channels such as search engines, social media, email, and websites to promote products or services. It’s important because it allows businesses to reach a larger audience, target specific demographics, and measure the effectiveness of their marketing efforts. Unlike traditional marketing, Digital Marketing provides real-time data and analytics, enabling businesses to adjust strategies for better results.' },
//                             { question: ' How can Digital Marketing help my business grow?', answer: 'Digital Marketing can help your business grow by increasing brand visibility, attracting targeted traffic, and generating qualified leads. By leveraging various online channels, you can engage with potential customers at different stages of the buying journey, build trust, and convert them into loyal customers. Additionally, the ability to track and analyze data allows you to optimize campaigns for maximum ROI.' },
//                             { question: ' What is remarketing, and how does it work?', answer: 'Remarketing (or retargeting) is a strategy used to reach out to users who have previously visited your website but did not convert (e.g., make a purchase or fill out a form). By using cookies, remarketing tracks visitors and displays targeted ads to them as they browse other websites or use social media. This technique helps remind potential customers of your products or services, increasing the chances of conversion by keeping your brand top of mind.' },
//                             { question: 'What is SEO, and how does it impact my website?', answer: 'SEO (Search Engine Optimization) is the practice of optimizing your website to rank higher in search engine results pages (SERPs). SEO involves on-page factors like keyword optimization, meta tags, and content quality, as well as off-page factors like backlinks and social signals. A well-optimized website can attract more organic traffic, improve user experience, and increase the likelihood of converting visitors into customers.' },
//                             { question: ' What is content marketing, and how does it benefit my business?', answer: 'Content marketing is the strategy of creating and distributing valuable, relevant, and consistent content to attract and engage a clearly defined audience. This content can include blog posts, videos, infographics, eBooks, and more. The benefits of content marketing include building brand authority, improving SEO, nurturing leads, and driving conversions. By providing valuable information, your business can establish trust with potential customers and position itself as an industry leader.' }
//                         ].map((faq, index) => (
//                             <div className="DM-faq-item" key={index}>
//                                 <button className="DM-faq-question" onClick={() => toggleFAQ(index)}>
//                                     <span className="DM-faq-symbol-circle"><span className="faq-symbol">{activeFAQ === index ? '-' : '+'}</span></span>
//                                     {faq.question}
//                                 </button>
//                                 {activeFAQ === index && (
//                                     <div className="DM-faq-answer">
//                                         <p>{faq.answer}</p>
//                                     </div>
//                                 )}
//                             </div>
//                         ))}
//                     </div>
//                 </section>
//             </div>
//         <CallToAction/>
//         <Footer/>
//         </>
//     );
// };

// export default Digitalmarketing;





import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// Importing Images
import DigitalRemovebg from '../img/servicesIMG/DigitalMarketing/DigitalRemovebg.png';
import DigitalService from '../img/servicesIMG/DigitalMarketing/DigitalService.png';
import BrandAwareness from '../img/servicesIMG/DigitalMarketing/BrandAwareness.png';
import CostEffective from '../img/servicesIMG/DigitalMarketing/CostEffective.png';
import LeadGeneration from '../img/servicesIMG/DigitalMarketing/LeadGeneration.png';
import MarketingAutomation from '../img/servicesIMG/DigitalMarketing/MarketingAutomation.png';
import MeasuringResult from '../img/servicesIMG/DigitalMarketing/MeasuringResult.png';
import TargetReach from '../img/servicesIMG/DigitalMarketing/TargetReach.png';

import CallToAction from './CallToAction';
import Footer from './Footer';

import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/DigitalMarketing.css';

const Digitalmarketing = () => {
    const navigate = useNavigate();

    const Click = () => {
        navigate('/Contact');
    };
    const [activeIndex, setActiveIndex] = useState(null);
    const [selectedService, setSelectedService] = useState(null);
    const [activeFAQ, setActiveFAQ] = useState(null);
    const fadeInSectionRefs = useRef([]);

    
    const showDMdetails = (service) => {
        setSelectedService(service);
    };
    
    const hideDetails = () => {
        setSelectedService(null);
    };
    
    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
      };

    const faqItems = [ 
        { question: 'What is Digital Marketing, and why is it important?', answer: 'Digital Marketing refers to the use of online channels such as search engines, social media, email, and websites to promote products or services. It’s important because it allows businesses to reach a larger audience, target specific demographics, and measure the effectiveness of their marketing efforts. Unlike traditional marketing, Digital Marketing provides real-time data and analytics, enabling businesses to adjust strategies for better results.' },
        { question: ' How can Digital Marketing help my business grow?', answer: 'Digital Marketing can help your business grow by increasing brand visibility, attracting targeted traffic, and generating qualified leads. By leveraging various online channels, you can engage with potential customers at different stages of the buying journey, build trust, and convert them into loyal customers. Additionally, the ability to track and analyze data allows you to optimize campaigns for maximum ROI.' },
        { question: ' What is remarketing, and how does it work?', answer: 'Remarketing (or retargeting) is a strategy used to reach out to users who have previously visited your website but did not convert (e.g., make a purchase or fill out a form). By using cookies, remarketing tracks visitors and displays targeted ads to them as they browse other websites or use social media. This technique helps remind potential customers of your products or services, increasing the chances of conversion by keeping your brand top of mind.' },
        { question: 'What is SEO, and how does it impact my website?', answer: 'SEO (Search Engine Optimization) is the practice of optimizing your website to rank higher in search engine results pages (SERPs). SEO involves on-page factors like keyword optimization, meta tags, and content quality, as well as off-page factors like backlinks and social signals. A well-optimized website can attract more organic traffic, improve user experience, and increase the likelihood of converting visitors into customers.' },
        { question: ' What is content marketing, and how does it benefit my business?', answer: 'Content marketing is the strategy of creating and distributing valuable, relevant, and consistent content to attract and engage a clearly defined audience. This content can include blog posts, videos, infographics, eBooks, and more. The benefits of content marketing include building brand authority, improving SEO, nurturing leads, and driving conversions. By providing valuable information, your business can establish trust with potential customers and position itself as an industry leader.' }
    ];

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );
        fadeInSectionRefs.current.forEach((section) => {
            if (section) {
                observer.observe(section);
            }
        });
        return () => {
            fadeInSectionRefs.current.forEach((section) => {
                if (section) observer.unobserve(section);
            });
        };
    }, []);

    useEffect(() => {
        const animateCounter = () => {
            const counters = document.querySelectorAll('.counter-value');
            const speed = 5000;
            counters.forEach(counter => {
                const updateCount = () => {
                    const target = +counter.getAttribute('data-target');
                    const count = +counter.innerText;
                    const inc = target / speed;
                    if (count < target) {
                        counter.innerText = Math.ceil(count + inc);
                        setTimeout(updateCount, 1);
                    } else {
                        counter.innerText = target;
                    }
                };
                updateCount();
            });
        };
        animateCounter();
    }, []);

    // Scroll to top on page load
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div>
                <section className="DM-title-section text-center py-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
                    <div className="DM-container container">
                        <h1 className="text-center">
                            Digital <span style={{ color: '#fcc101' }}>Marketing</span>
                        </h1>
                        <p className="text-center" style={{ color: 'black' }}>
                            At BizKranti, we provide comprehensive Digital marketing solutions to bring your vision to life.
                        </p>
                    </div>
                </section>

                <section className="DM-services fade-in-section" id="services" ref={(el) => fadeInSectionRefs.current.push(el)}>
                    <h2 className="text-center fs-1">
                        <b>Our <span style={{ color: '#fcc101' }}>Services</span></b>
                    </h2>
                    <div className="DM-container-fluid d-flex align-items-center justify-content-between py-5">
                        <div className="DM-services-content">
                            <div className="DM-services-image">
                                <img src={DigitalService} alt="Isometric illustration of diverse individuals working together on a laptop, focused on digital marketing efforts and collaboration." />
                            </div>
                            <div className="DM-service-boxes">
                                <div className="DM-service-box zoom-in" onClick={() => showDMdetails('BrandAwareness')}>
                                    <div className="DM-services-image1">
                                        <img src={BrandAwareness} alt="A black background showcases a white clock, illustrating the vital relationship between time management and brand awareness." />
                                    </div>
                                    <h3 className="DM-service-boxes-title fs-4">Brand Awareness</h3>
                                    <p>Crafting beautiful and responsive websites.</p>
                                </div>
                                <div className="DM-service-box zoom-in" onClick={() => showDMdetails('CostEffective')}>
                                    <div className="DM-Graphic">
                                        <img src={CostEffective} alt="A sleek white clock on a black background, representing a stylish and cost-effective solution for timekeeping." />
                                    </div>
                                    <h3 className="DM-service-boxes-title fs-4">Cost Effective</h3>
                                    <p>Building robust online stores.</p>
                                </div>
                                <div className="DM-service-box zoom-in" onClick={() => showDMdetails('LeadGeneration')}>
                                    <div className="DM-packaging">
                                        <img src={LeadGeneration} alt="A minimalist white clock set against a black backdrop, illustrating the significance of time management in lead generation." />
                                    </div>
                                    <h3 className="DM-service-boxes-title fs-4">Lead Generation</h3>
                                    <p>Boost your website's visibility on search engines.</p>
                                </div>
                                <div className="DM-service-box zoom-in" onClick={() => showDMdetails('MarketingAutomation')}>
                                    <div className="DM-Marketing">
                                        <img src={MarketingAutomation} alt="A striking white clock set against a black backdrop, illustrating the importance of timing in marketing automation efforts." />
                                    </div>
                                    <h3 className="DM-service-boxes-title fs-4">Marketing Automation</h3>
                                    <p>Building robust online stores.</p>
                                </div>
                                <div className="DM-service-box zoom-in" onClick={() => showDMdetails('MeasuringResult')}>
                                    <div className="DM-Web">
                                        <img src={MeasuringResult} alt="A visually appealing image of a white clock on a black background, highlighting the essence of timing in marketing success." />
                                    </div>
                                    <h3 className="DM-service-boxes-title fs-4">Measuring Result</h3>
                                    <p>Building robust online stores.</p>
                                </div>
                                <div className="DM-service-box zoom-in" onClick={() => showDMdetails('TargetReach')}>
                                    <div className="DM-rebranding">
                                        <img src={TargetReach} alt="A minimalist design featuring a white clock on a black background, creating a bold and elegant visual representation of time." />
                                    </div>
                                    <h3 className="DM-service-boxes-title fs-4">Target Reach</h3>
                                    <p>Building robust online stores.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {selectedService && (
                    <section className="DM-details" id="details">
                        <div className="DM-details-content show">
                            <h2 id="details-title">{selectedService}</h2>
                            <p id="details-description">
                                {selectedService === 'TargetReach' && 'Focus on identifying and reaching the right audience for your product or service. Use data-driven strategies to ensure your marketing efforts are directed at the most relevant segments, maximizing impact and engagement.'}
                                {selectedService === 'MarketingAutomation' && 'Use software to automate repetitive marketing tasks, such as email campaigns, social media posting, and lead nurturing. This streamlines processes, saves time, and ensures consistent communication with your audience.'}
                                {selectedService === 'MeasuringResult' && 'Implement tools and metrics to track the success of your marketing campaigns. Analyze key performance indicators (KPIs) like conversion rates, customer acquisition costs, and return on investment (ROI) to assess effectiveness and make data-driven decisions.'}
                                {selectedService === 'LeadGeneration' && 'Develop strategies to attract and capture potential customers interest, turning them into leads. Use content marketing, social media, and SEO to drive traffic, and employ landing pages and forms to gather contact information.'}
                                {selectedService === 'CostEffective' && 'Optimize your marketing budget by focusing on high-impact, low-cost strategies. Use digital marketing techniques like social media marketing, content creation, and email campaigns to reach a broad audience without significant expenses.'}
                                {selectedService === 'BrandAwareness' && 'Build and maintain a strong brand presence in the market. Use consistent messaging, visual identity, and storytelling to establish your brand and make it recognizable. Engage in activities that increase visibility, such as social media, content marketing, and public relations.'}
                            </p>
                            <button className="bt1" onClick={hideDetails}>Close</button>
                            <button className="bt2" onClick={Click}>Enquire Us</button>
                        </div>
                    </section>
                )}

      {/* FAQ Section */}
      <section id="FAQ" className="DM-FAQ fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
        <div className="DM-faq-footer">
          <h2 className="DM-faq-header">
            Frequently <span style={{ color: '#fcc101' }}>Asked Questions</span>
          </h2>
          {faqItems.map((item, index) => (
            <div className="DM-faq-item" key={index}>
              <button
                className="DM-faq-question"
                onClick={() => toggleFAQ(index)}
                aria-expanded={activeIndex === index}
              >
                <span className="DM-faq-symbol-circle">
                  <span className="faq-symbol">{activeIndex === index ? '-' : '+'}</span>
                </span>
                {item.question}
              </button>
              <div className={`DM-faq-answer ${activeIndex === index ? 'd-block' : 'd-none'}`}>
                <p>{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

                <section>
                    <div className="container-fluid DM-CallToAction text-center p-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
                        <h1 className='text-white text-center'>
                            Contact Us for a  <span style={{ color: '#ffc101' }}>Custom Strategy</span>
                        </h1>
                        <h4 className="text-white text-center">
                            Need Results-Driven Marketing? Talk to Our Experts Now And Boost Your Traffic and Sales
                            Get a Quote Today
                        </h4>
                        <button type="button" className="btn btn-warning mt-4" onClick={() => navigate('/Contact')}>
                            CONTACT WITH US
                        </button>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    );
};

export default Digitalmarketing;
