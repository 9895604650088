import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/UiUxDesign.css';

// Import images
import UIUX from '../img/servicesIMG/UIUX/UIUX.png';
import ReducedDevelopmentCosts from '../img/servicesIMG/UIUX/ReducedDevelopmentCosts.png';
import ImproveBrandReputation from '../img/servicesIMG/UIUX/ImproveBrandReputation.png';
import SeoFriendly from '../img/servicesIMG/UIUX/SeoFriendly.png';
import RatesNSales from '../img/servicesIMG/UIUX/RatesNSales.png';

// Import components
import Footer from './Footer';
import CallToAction from './CallToAction';

function UiUxDesign() {
  const [activeIndex, setActiveIndex] = useState(null);
  const fadeInSectionRefs = useRef([]);

  const navigate = useNavigate();

  const faqItems = [
    {
      question: 'What exactly is UX design?',
      answer: 'User experience (UX) design is an umbrella term representing the entire process of creating products and services that provide intuitive and delightful experiences to their users.',
    },
    {
      question: 'How much would a typical UX design project cost?',
      answer: 'The cost of a UX design project depends on several factors, including scope, scale, platforms involved, and services included.',
    },
    {
      question: 'What is the timeline for a UX design project?',
      answer: 'The timeline varies based on complexity and scope. It can range from 2-3 months for smaller projects to over 6 months for larger ones.',
    },
    {
      question: 'What inputs and data does the client need to provide?',
      answer: 'Clients should provide project goals, target audience information, and any existing design materials before starting a UX design project.',
    },
    {
      question: 'How can good User Experience help businesses?',
      answer: 'Good UX design enhances customer satisfaction, boosts brand reputation, and ultimately improves the bottom line.',
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const handleClick = () => {
    navigate('/Contact');
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    fadeInSectionRefs.current.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      fadeInSectionRefs.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  useEffect(() => {
    const animateCounter = () => {
      const counters = document.querySelectorAll('.counter-value');
      const speed = 5000; // The lower the speed, the faster the counter

      counters.forEach(counter => {
        const updateCount = () => {
          const target = +counter.getAttribute('data-target');
          const count = +counter.innerText;
          const inc = target / speed;

          if (count < target) {
            counter.innerText = Math.ceil(count + inc);
            setTimeout(updateCount, 1);
          } else {
            counter.innerText = target;
          }
        };

        updateCount();
      });
    };

    animateCounter();
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Main UI/UX Section */}
      <section className="container-fluid MainUiUX d-flex align-items-center justify-content-between py-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
        <div className="UiUXName">
          <h1>UI and UX <span className="highlight-blue">Design <br />Services</span></h1>
          <h4>Design compelling in-app experiences for your users using cutting-edge and intuitive UX designs.</h4>
          <button type="button" className="btn btn-primary UIUXBTN" aria-label="Book a Meeting" onClick={handleClick}>Book a Meeting</button>
        </div>
        <div>
          <img src={UIUX} className="img-fluid d-block mx-auto MainUiUXIMG" alt="An illustration of UI/UX design that incorporates modern technologies to elevate user experience and interface aesthetics." />
        </div>
      </section>

      {/* Immersive UI/UX Section */}
      <section className="code-UIUX text-center py-5" ref={(el) => fadeInSectionRefs.current.push(el)}>
        <div className="container">
          <h1 className="text-white">Immersive <span style={{ color: '#fcc101' }}>UI and UX Design</span> for a Flawless <span className="highlight-yellow">App Experience</span></h1>
          <p className="text-white">
            Bizkranti Software has established itself as a top provider of UI and UX design services, supported by years of experience in digital transformation. Our team offers design principles that enhance your technological capabilities, helping you lead in the mobile app development market.
          </p>
        </div>
      </section>

      {/* Why Prefer UI/UX Section */}
      <section className="Why-PreferUIUX fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
        <h1>Why Businesses Prefer UI and <span style={{ color: '#fcc101' }}>UX Design Services</span></h1>
        <div className="Why-Option">
          <div className="Why-Option-card">
            <div>
              <img src={ReducedDevelopmentCosts} className="UIUXImg img-fluid" alt="A white clock stands out against a black background, creating a bold visual representation of time in a minimalist style." />
            </div>
            <div>
              <h2><span className="highlight-yellow">Reduced Development Costs</span></h2>
              <p>
                A provider of UI services is skilled in creating interfaces that are easy to use. This benefits businesses by fostering the development of an agile app interface, preventing costly redesigns, and reducing financial and time waste.
              </p>
            </div>
          </div>
          <div className="Why-Option-card">
            <div>
              <img src={ImproveBrandReputation} className="UIUXImg img-fluid" alt="A striking white clock set against a black backdrop, conveying a message of punctuality and trustworthiness for brand image." />
            </div>
            <div>
              <h2><span className="highlight-yellow">Improve Brand Reputation</span></h2>
              <p>
                A well-designed digital product demonstrates to consumers that the business values their patronage, leading to higher retention rates and enhanced brand reputation.
              </p>
            </div>
          </div>
        </div>
        <div className="Why-Option option">
          <div className="Why-Option-card">
            <div>
              <img src={SeoFriendly} className="UIUXImg img-fluid" alt="Minimalist image featuring a white clock on a black background, highlighting the contrast and elegance of timekeeping." />
            </div>
            <div>
              <h2><span className="highlight-yellow">SEO-Friendly</span></h2>
              <p>
                A user-friendly and intuitive website enhances search engine optimization efforts, leading to better rankings, reduced bounce rates, and increased click-through rates.
              </p>
            </div>
          </div>
          <div className="Why-Option-card">
            <div>
              <img src={RatesNSales} className="UIUXImg img-fluid" alt="A white clock on a black background, highlighting the essential connection between time management and increased sales and conversion rates." />
            </div>
            <div>
              <h2><span className="highlight-yellow">Enhanced Conversion Rates and Sales</span></h2>
              <p>
                An immersive and well-designed user interface increases the likelihood of customer purchases, leading to higher conversion rates and profits for businesses.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section id="FAQ" className="UUD-FAQ fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
        <div className="UUD-faq-footer">
          <h2 className="UUD-faq-header">Frequently <span className="highlight-yellow" style={{ color: '#fcc101' }}>Asked Questions</span></h2>

          {faqItems.map((item, index) => (
            <div className="UUD-faq-item" key={index}>
              <button
                className="UUD-faq-question"
                onClick={() => toggleFAQ(index)}
                aria-expanded={activeIndex === index}
              >
                <span className="UUD-faq-symbol-circle"><span className="faq-symbol">{activeIndex === index ? '-' : '+'}</span></span>
                {item.question}
              </button>
              <div className={`UUD-faq-answer ${activeIndex === index ? 'd-block' : 'd-none'}`}>
                <p>{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section>
        <div className="container-fluid UIUX-CallToAction text-center p-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
          <h1 className='text-white text-center'>
            Let’s Design  <span style={{ color: '#ffc101' }}>Together</span>
          </h1>
          <h4 className="text-white text-center">
            Build Engaging Interfaces – Get in Touch for a Custom UI/UX Solution
            Get a Quote Today
          </h4>
          <button type="button" className="btn btn-warning mt-4" onClick={() => navigate('/Contact')}>
            CONTACT WITH US
          </button>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default UiUxDesign;
