// import React, { useState } from 'react';
// import { useInView } from 'react-intersection-observer';
// import { useNavigate } from 'react-router-dom';
// import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import '../css/TechnologiesWeWork.css';

// const technologySets = {
//     WebDevelopment: [
//         { name: 'html', imgSrc: require('../img/technologies/html.png'), alt: 'A visual representation of the HTML5 logo, highlighting its significance in app development and web technologies.' },
//         { name: 'css', imgSrc: require('../img/technologies/css.png'), alt: 'CSS3 logo representing modern web styling technology' },
//         { name: 'javaScript', imgSrc: require('../img/technologies/javaScript.png'), alt: 'JavaScript logo against a sunny yellow background, illustrating its well-known branding in a lively color scheme.' },
//         { name: 'reactJS', imgSrc: require('../img/technologies/reactJS.png'), alt: 'Illustration of React Native, showcasing the library designed for building user interfaces with modular JavaScript components.' },
//         { name: 'nodeJS', imgSrc: require('../img/technologies/nodeJS.png'), alt: 'Node.js logo featured, highlighting its function as an open-source environment for server-side JavaScript programming.' },
//         { name: 'nextJS', imgSrc: require('../img/technologies/nextJS.png'), alt: 'A striking contrast of a white clock on a black background, illustrating the importance of time in high-quality web application development.' },
//         { name: 'Vue', imgSrc: require('../img/technologies/Vue.png'), alt: 'A green and blue `V` logo, embodying the Progressive JavaScript Framework designed for efficient web user interface development.' },
//         { name: 'angularJS', imgSrc: require('../img/technologies/angularJS.png'), alt: 'Angular logo, emblematic of a powerful framework for web developers, highlighting its unique angular design and versatility.' },
//         { name: 'java', imgSrc: require('../img/technologies/java.png'), alt: 'Java logo featuring a steaming cup of coffee,, illustrating the connection between programming and the creativity of app development.' },
//         { name: 'python', imgSrc: require('../img/technologies/python.png'), alt: 'Python logo accompanied by a snake, showcasing the programming language`s quick work and effective system integration features.' },
//         { name: 'php', imgSrc: require('../img/technologies/php.png'), alt: 'Image of PHP, showcasing its significance as a server scripting language for dynamic web page creation.' },
//     ],
//     AppDevelopment: [
//         { name: 'java', imgSrc: require('../img/technologies/java.png'), alt: 'Java logo featuring a steaming cup of coffee,, illustrating the connection between programming and the creativity of app development.' },
//         { name: 'ios', imgSrc: require('../img/technologies/ios.png'), alt: 'The Apple logo with "iOS" text, illustrating the connection between Apple`s brand and its app development platform.' },
//         { name: 'android', imgSrc: require('../img/technologies/android.png'), alt: ' An Android logo adorned with green eyes, illustrating the evolution of application development language.' },
//         { name: 'swift', imgSrc: require('../img/technologies/swift.png'), alt: 'Icon for Swift, an intuitive programming language for Apple platforms, showcasing a contemporary and visually appealing style.' },
//         { name: 'flutter', imgSrc: require('../img/technologies/flutter.png'), alt: 'Logo for F Logo, illustrating a forward-thinking approach to app development with a clean and modern look.' },
//         { name: 'kotlin', imgSrc: require('../img/technologies/kotlin.png'), alt: ' A striking triangle in vibrant colors set against a black and orange background, illustrating a bold geometric aesthetic.' },
//     ],
//     CloudDevelopment: [
//         { name: 'Python', imgSrc: require('../img/technologies/python.png'), alt: 'Python logo accompanied by a snake, showcasing the programming language`s quick work and effective system integration features.' },
//         { name: 'Jenkin', imgSrc: require('../img/technologies/jenkin.png'), alt: 'Jenkin logo showcasing their software expertise, emphasizing server capabilities and a sleek, professional aesthetic.' },
//         { name: 'aws', imgSrc: require('../img/technologies/aws.png'), alt: 'AWS logo displaying the word "AWS," highlighting strong server capabilities and innovative cloud technology.' },
//         { name: 'apacheTomcat', imgSrc: require('../img/technologies/apacheTomcat.png'), alt: ' Apache Tomcat logo, showcasing a distinctive cat emblem, symbolizing the Java-based web server technology.' },
//         { name: 'azur', imgSrc: require('../img/technologies/azur.png'), alt: ' The Adobe Illustrator logo, representing graphic design excellence, showcased on a robust server infrastructure.' },
//         { name: 'cluster', imgSrc: require('../img/technologies/cluster.png'), alt: 'Cluster logo displayed prominently on a black background, symbolizing a powerful server network and robust performance.' },
//     ],
// };

// const TechnologiesWeWork = () => {
//     const navigate = useNavigate();

//     const handleImageClick = (technologyName) => {
//         switch (technologyName.toLowerCase()) {
//             case 'html':
//             case 'css':
//             case 'javascript':
//             case 'reactjs':
//             case 'nodejs':
//             case 'nextjs':
//             case 'vue':
//             case 'angularjs':
//             case 'java':
//             case 'python':
//             case 'php':
//                 navigate('/services/webDevelopment#WebTechnologies');
//                 break;
//             case 'ios':
//             case 'android':
//             case 'swift':
//             case 'flutter':
//             case 'kotlin':
//                 navigate('/services/AppDevelopment#AppTechnologies');
//                 break;
//                 // case 'jenkin':
//                 // case 'aws':
//                 // case 'apachetomcat':
//                 // case 'azur':
//                 // case 'cluster':
//                 // navigate('/services/CloudDevelopment');
//                 break;
//             default:
//                 break;
//         }
//     };

//     const { ref: sectionRef, inView: sectionInView } = useInView({
//         triggerOnce: true,
//         threshold: 0.1,
//     });

//     const [currentSet, setCurrentSet] = useState('WebDevelopment');

//     const handleButtonClick = (setName) => {
//         setCurrentSet(setName);
//     };

//     return (
//         <section className="TechnologiesWeWork py-5" ref={sectionRef}>
//             {/* <h1 className="animated-title " >
//                 <span style={{ color: 'black' }}>Technologies </span>
//                 <span className="text-warning">We Work</span>
//                 <p className="lead" style={{ color: '#14234c' }}>
//                     <b>We are Creating Projects</b> with all these Technologies.
//                 </p>
//             </h1> */}

// <h1 className={`animated-title ${sectionInView ? 'animate-in' : ''}`}>
//                 <span style={{ color: 'black' }}>Technologies </span>
//                 <span className="text-warning">We Work</span>
//                 <p className="lead" style={{ color: '#14234c' }}>
//                     <b>We are Creating Projects</b> with all these Technologies.
//                 </p>
//             </h1>

//             <div className='d-flex flex-wrap justify-content-center mt-5 mb-5' style={{ gap: '100px' }}>
//                 <button onClick={() => handleButtonClick('WebDevelopment')} className='Technologies-btn'>
//                     <span className='Technologies-btns'> <span>Web Development</span></span>
//                 </button>
//                 <button onClick={() => handleButtonClick('AppDevelopment')} className='Technologies-btn'>
//                     <span className='Technologies-btns'> <span>App Development</span></span>
//                 </button>
//                 <button onClick={() => handleButtonClick('CloudDevelopment')} className='Technologies-btn'>
//                     <span className='Technologies-btns'> <span>Cloud Development</span></span>
//                 </button>
//             </div>

//             <div className={`Technologies-techno ${sectionInView ? 'Technologies-fade-in' : ''}`}>
//                 {technologySets[currentSet].map((tech, index) => (
//                     <div className={`Technologies-container container ${sectionInView ? 'slide-in' : ''}`} key={index}>
//                         <img

//                             src={tech.imgSrc}
//                             alt={tech.alt}
//                             className="Technologies-image"
//                             aria-label={tech.name}
//                             style={{ width: '150px' }}
//                         />
//                         <div
//                             onClick={() => handleImageClick(tech.name)}
//                             className="Technologies-overlay">
//                             <div className="Technologies-text">{tech.name}</div>
//                         </div>
//                     </div>
//                 ))}
//             </div>
//         </section>
//     );
// };

// export default TechnologiesWeWork;




// import React, { useState } from 'react';
// import { useInView } from 'react-intersection-observer';
// import { useNavigate } from 'react-router-dom';
// import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import '../css/TechnologiesWeWork.css';

//  const technologySets = {
//      WebDevelopment: [
//          { name: 'html', imgSrc: require('../img/technologies/html.png'), alt: 'A visual representation of the HTML5 logo, highlighting its significance in app development and web technologies.' },
//          { name: 'css', imgSrc: require('../img/technologies/css.png'), alt: 'CSS3 logo representing modern web styling technology' },
//          { name: 'javaScript', imgSrc: require('../img/technologies/javaScript.png'), alt: 'JavaScript logo against a sunny yellow background, illustrating its well-known branding in a lively color scheme.' },
//          { name: 'reactJS', imgSrc: require('../img/technologies/reactJS.png'), alt: 'Illustration of React Native, showcasing the library designed for building user interfaces with modular JavaScript components.' },
//          { name: 'nodeJS', imgSrc: require('../img/technologies/nodeJS.png'), alt: 'Node.js logo featured, highlighting its function as an open-source environment for server-side JavaScript programming.' },
//          { name: 'nextJS', imgSrc: require('../img/technologies/nextJS.png'), alt: 'A striking contrast of a white clock on a black background, illustrating the importance of time in high-quality web application development.' },
//          { name: 'Vue', imgSrc: require('../img/technologies/Vue.png'), alt: 'A green and blue `V` logo, embodying the Progressive JavaScript Framework designed for efficient web user interface development.' },
//          { name: 'angularJS', imgSrc: require('../img/technologies/angularJS.png'), alt: 'Angular logo, emblematic of a powerful framework for web developers, highlighting its unique angular design and versatility.' },
//          { name: 'java', imgSrc: require('../img/technologies/java.png'), alt: 'Java logo featuring a steaming cup of coffee,, illustrating the connection between programming and the creativity of app development.' },
//          { name: 'python', imgSrc: require('../img/technologies/python.png'), alt: 'Python logo accompanied by a snake, showcasing the programming language`s quick work and effective system integration features.' },
//          { name: 'php', imgSrc: require('../img/technologies/php.png'), alt: 'Image of PHP, showcasing its significance as a server scripting language for dynamic web page creation.' },
//      ],
//      AppDevelopment: [
//          { name: 'java', imgSrc: require('../img/technologies/java.png'), alt: 'Java logo featuring a steaming cup of coffee,, illustrating the connection between programming and the creativity of app development.' },
//          { name: 'ios', imgSrc: require('../img/technologies/ios.png'), alt: 'The Apple logo with "iOS" text, illustrating the connection between Apple`s brand and its app development platform.' },
//          { name: 'android', imgSrc: require('../img/technologies/android.png'), alt: ' An Android logo adorned with green eyes, illustrating the evolution of application development language.' },
//          { name: 'swift', imgSrc: require('../img/technologies/swift.png'), alt: 'Icon for Swift, an intuitive programming language for Apple platforms, showcasing a contemporary and visually appealing style.' },
//          { name: 'flutter', imgSrc: require('../img/technologies/flutter.png'), alt: 'Logo for F Logo, illustrating a forward-thinking approach to app development with a clean and modern look.' },
//          { name: 'kotlin', imgSrc: require('../img/technologies/kotlin.png'), alt: ' A striking triangle in vibrant colors set against a black and orange background, illustrating a bold geometric aesthetic.' },
//      ],
//      CloudDevelopment: [
//          { name: 'Python', imgSrc: require('../img/technologies/python.png'), alt: 'Python logo accompanied by a snake, showcasing the programming language`s quick work and effective system integration features.' },
//          { name: 'Jenkin', imgSrc: require('../img/technologies/jenkin.png'), alt: 'Jenkin logo showcasing their software expertise, emphasizing server capabilities and a sleek, professional aesthetic.' },
//          { name: 'aws', imgSrc: require('../img/technologies/aws.png'), alt: 'AWS logo displaying the word "AWS," highlighting strong server capabilities and innovative cloud technology.' },
//          { name: 'apacheTomcat', imgSrc: require('../img/technologies/apacheTomcat.png'), alt: ' Apache Tomcat logo, showcasing a distinctive cat emblem, symbolizing the Java-based web server technology.' },
//          { name: 'azur', imgSrc: require('../img/technologies/azur.png'), alt: ' The Adobe Illustrator logo, representing graphic design excellence, showcased on a robust server infrastructure.' },
//          { name: 'cluster', imgSrc: require('../img/technologies/cluster.png'), alt: 'Cluster logo displayed prominently on a black background, symbolizing a powerful server network and robust performance.' },
//      ],
//  };

// // Define the toggle button switcher component
// const TechnologiesWeWork = () => {
//     const navigate = useNavigate();

//     const handleImageClick = (technologyName) => {
//         switch (technologyName.toLowerCase()) {
//             case 'html':
//             case 'css':
//             case 'javascript':
//             case 'reactjs':
//             case 'nodejs':
//             case 'nextjs':
//             case 'vue':
//             case 'angularjs':
//             case 'java':
//             case 'python':
//             case 'php':
//                 navigate('/services/webDevelopment#WebTechnologies');
//                 break;
//             case 'ios':
//             case 'android':
//             case 'swift':
//             case 'flutter':
//             case 'kotlin':
//                 navigate('/services/AppDevelopment#AppTechnologies');
//                 break;
//             default:
//                 break;
//         }
//     };

//     const { ref: sectionRef, inView: sectionInView } = useInView({
//         triggerOnce: true,
//         threshold: 0.1,
//     });

//     // Define states to track the current selected option
//     const [currentSet, setCurrentSet] = useState('WebDevelopment');

//     // Array of option names to cycle through
//     const options = ['WebDevelopment', 'AppDevelopment', 'CloudDevelopment'];

//     // Handle switching between the options
//     const handleSwitchClick = () => {
//         const currentIndex = options.indexOf(currentSet);
//         const nextIndex = (currentIndex + 1) % options.length;
//         setCurrentSet(options[nextIndex]);
//     };

//     return (
//         <section className="TechnologiesWeWork py-5" ref={sectionRef}>
//             <h1 className={`animated-title ${sectionInView ? 'animate-in' : ''}`}>
//                 <span style={{ color: 'black' }}>Technologies </span>
//                 <span className="text-warning">We Work</span>
//                 <p className="lead" style={{ color: '#14234c' }}>
//                     <b>We are Creating Projects</b> with all these Technologies.
//                 </p>
//             </h1>

//             {/* Toggle Switcher */}
//             <div className="toggle-switcher">
//                 <span className="option-label">Selected: {currentSet}</span>
//                 <button className="toggle-btn" onClick={handleSwitchClick}>
//                     Toggle: {currentSet === 'WebDevelopment' ? 'Web' :
//                         currentSet === 'AppDevelopment' ? 'App' : 'Cloud'}
//                 </button>
//             </div>

//             {/* Display technologies based on current set */}
//             <div className={`Technologies-techno ${sectionInView ? 'Technologies-fade-in' : ''}`}>
//                 {technologySets[currentSet].map((tech, index) => (
//                     <div className={`Technologies-container container ${sectionInView ? 'slide-in' : ''}`} key={index}>
//                         <img
//                             src={tech.imgSrc}
//                             alt={tech.alt}
//                             className="Technologies-image"
//                             aria-label={tech.name}
//                             style={{ width: '150px' }}
//                         />
//                         <div
//                             onClick={() => handleImageClick(tech.name)}
//                             className="Technologies-overlay"
//                         >
//                             <div className="Technologies-text">{tech.name}</div>
//                         </div>
//                     </div>
//                 ))}
//             </div>
//         </section>
//     );
// };

// export default TechnologiesWeWork;



import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/TechnologiesWeWork.css';

const technologySets = {
    WebDevelopment: [
        { name: 'html', imgSrc: require('../img/technologies/html.png'), alt: 'A visual representation of the HTML5 logo, highlighting its significance in app development and web technologies.' },
        { name: 'css', imgSrc: require('../img/technologies/css.png'), alt: 'CSS3 logo representing modern web styling technology' },
        { name: 'javaScript', imgSrc: require('../img/technologies/javaScript.png'), alt: 'JavaScript logo against a sunny yellow background, illustrating its well-known branding in a lively color scheme.' },
        { name: 'reactJS', imgSrc: require('../img/technologies/reactJS.png'), alt: 'Illustration of React Native, showcasing the library designed for building user interfaces with modular JavaScript components.' },
        { name: 'nodeJS', imgSrc: require('../img/technologies/nodeJS.png'), alt: 'Node.js logo featured, highlighting its function as an open-source environment for server-side JavaScript programming.' },
        { name: 'nextJS', imgSrc: require('../img/technologies/nextJS.png'), alt: 'A striking contrast of a white clock on a black background, illustrating the importance of time in high-quality web application development.' },
        { name: 'Vue', imgSrc: require('../img/technologies/Vue.png'), alt: 'A green and blue `V` logo, embodying the Progressive JavaScript Framework designed for efficient web user interface development.' },
        { name: 'angularJS', imgSrc: require('../img/technologies/angularJS.png'), alt: 'Angular logo, emblematic of a powerful framework for web developers, highlighting its unique angular design and versatility.' },
        { name: 'java', imgSrc: require('../img/technologies/java.png'), alt: 'Java logo featuring a steaming cup of coffee,, illustrating the connection between programming and the creativity of app development.' },
        { name: 'python.', imgSrc: require('../img/technologies/python.png'), alt: 'Python logo accompanied by a snake, showcasing the programming language`s quick work and effective system integration features.' },
        { name: 'php', imgSrc: require('../img/technologies/php.png'), alt: 'Image of PHP, showcasing its significance as a server scripting language for dynamic web page creation.' },
    ],
    AppDevelopment: [
        { name: 'java', imgSrc: require('../img/technologies/java.png'), alt: 'Java logo featuring a steaming cup of coffee,, illustrating the connection between programming and the creativity of app development.' },
        { name: 'ios', imgSrc: require('../img/technologies/ios.png'), alt: 'The Apple logo with "iOS" text, illustrating the connection between Apple`s brand and its app development platform.' },
        { name: 'android', imgSrc: require('../img/technologies/android.png'), alt: ' An Android logo adorned with green eyes, illustrating the evolution of application development language.' },
        { name: 'swift', imgSrc: require('../img/technologies/swift.png'), alt: 'Icon for Swift, an intuitive programming language for Apple platforms, showcasing a contemporary and visually appealing style.' },
        { name: 'flutter', imgSrc: require('../img/technologies/flutter.png'), alt: 'Logo for F Logo, illustrating a forward-thinking approach to app development with a clean and modern look.' },
        { name: 'kotlin', imgSrc: require('../img/technologies/kotlin.png'), alt: ' A striking triangle in vibrant colors set against a black and orange background, illustrating a bold geometric aesthetic.' },
    ],
    CloudDevelopment: [
        { name: 'Python', imgSrc: require('../img/technologies/python.png'), alt: 'Python logo accompanied by a snake, showcasing the programming language`s quick work and effective system integration features.' },
        { name: 'Jenkin', imgSrc: require('../img/technologies/jenkin.png'), alt: 'Jenkin logo showcasing their software expertise, emphasizing server capabilities and a sleek, professional aesthetic.' },
        { name: 'aws', imgSrc: require('../img/technologies/aws.png'), alt: 'AWS logo displaying the word "AWS," highlighting strong server capabilities and innovative cloud technology.' },
        { name: 'apacheTomcat', imgSrc: require('../img/technologies/apacheTomcat.png'), alt: ' Apache Tomcat logo, showcasing a distinctive cat emblem, symbolizing the Java-based web server technology.' },
        { name: 'azur', imgSrc: require('../img/technologies/azur.png'), alt: ' The Adobe Illustrator logo, representing graphic design excellence, showcased on a robust server infrastructure.' },
        { name: 'cluster', imgSrc: require('../img/technologies/cluster.png'), alt: 'Cluster logo displayed prominently on a black background, symbolizing a powerful server network and robust performance.' },
    ],
};

// Define the toggle button switcher component
const TechnologiesWeWork = () => {
    const navigate = useNavigate();

    const handleImageClick = (technologyName) => {
        switch (technologyName.toLowerCase()) {
            case 'html':
            case 'css':
            case 'javascript':
            case 'reactjs':
            case 'nodejs':
            case 'nextjs':
            case 'vue':
            case 'angularjs':
            case 'java':
            case 'python.':
            case 'php':
                navigate('/services/WebDevelopment#WebTechnologies');
                break;
            case 'ios':
            case 'android':
            case 'swift':
            case 'flutter':
            case 'kotlin':
                navigate('/services/AppDevelopment#AppTechnologies');
                break;
            default:
                break;
        }
    };

    const { ref: sectionRef, inView: sectionInView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    // Define state to track the current selected option
    const [currentSet, setCurrentSet] = useState('WebDevelopment');

    // Array of option names to cycle through
    const options = ['WebDevelopment', 'AppDevelopment', 'CloudDevelopment'];

    // UseEffect to load and set the last selected section
    useEffect(() => {
        const storedSet = localStorage.getItem('lastViewedSet');
        if (storedSet) {
            setCurrentSet(storedSet);
        }
    }, []);

    // Handle switching between the options
    const handleSwitchClick = () => {
        const currentIndex = options.indexOf(currentSet);
        const nextIndex = (currentIndex + 1) % options.length;
        const nextSet = options[nextIndex];
        setCurrentSet(nextSet);
        // Store the selected set in localStorage
        localStorage.setItem('lastViewedSet', nextSet);
    };

    return (
        <section className="TechnologiesWeWork py-5" ref={sectionRef}>
            <h1 className={`animated-title ${sectionInView ? 'animate-in' : ''}`}>
                <span style={{ color: 'black' }}>Technologies </span>
                <span className="text-warning">We Work</span>
                <p className="lead" style={{ color: '#14234c' }}>
                    <b>We are Creating Projects</b> with all these Technologies.
                </p>
            </h1>

            {/* Toggle Switcher */}
            <div className="toggle-switcher">
                <span className="option-label">Selected: {currentSet}</span>
                <button className="toggle-btn" onClick={handleSwitchClick}>
                    {currentSet === 'WebDevelopment' ? 'Web' :
                        currentSet === 'AppDevelopment' ? 'App' : 'Cloud'}
                </button>
            </div>

            {/* Display technologies based on current set */}
            <div className={`Technologies-techno ${sectionInView ? 'Technologies-fade-in' : ''}`}>
                {technologySets[currentSet].map((tech, index) => (
                    <div className={`Technologies-container container ${sectionInView ? 'slide-in' : ''}`} key={index}>
                        <img
                            src={tech.imgSrc}
                            alt={tech.alt}
                            className="Technologies-image"
                            aria-label={tech.name}
                            style={{ width: '150px' }}
                        />
                        <div
                            onClick={() => handleImageClick(tech.name)}
                            className="Technologies-overlay"
                        >
                            <div className="Technologies-text">{tech.name}</div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default TechnologiesWeWork;
