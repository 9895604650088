import React, { useState, useEffect } from 'react';
import '../css/PopUp.css';

const PopUp = () => {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 10000);

        return () => clearTimeout(timer);
    }, []);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phonePattern = /^[6-9]\d{9}$/;

        let valid = true;

        if (!emailRegex.test(email)) {
            setEmailError('Please enter a valid email address.');
            valid = false;
        } else {
            setEmailError('');
        }

        if (!phonePattern.test(phone)) {
            setPhoneError('Please enter a valid Indian mobile number.');
            valid = false;
        } else {
            setPhoneError('');
        }

        if (valid) {
            const formData = {
                fullName: document.getElementById('first-name').value,
                email: email,
                mobileNumber: phone,
                yourCompanyName: document.getElementById('location').value,
                serviceInterested: document.getElementById('options').value,
                message: document.getElementById('message').value
            };

            try {
                const response = await fetch('http://localhost:8080/submit', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log('Form submitted successfully:', data);
                    setShowThankYou(true); // Update state to show thank you message
                    setIsVisible(false);   // Hide the form
                } else {
                    const errorText = await response.text();
                    console.error('Server error:', errorText);
                    alert('Failed to submit the form. Please try again.');
                }
            } catch (error) {
                console.error('Error submitting the form:', error);
                alert('An error occurred while submitting the form. Please try again.');
            }
        }
    };

    const handleClose = () => {
        setIsVisible(false);
        setShowThankYou(false);
    };

    return (
        <>
            {isVisible && !showThankYou && (
                <section className="Pop-ApplyForm">
                    <div className="Pop-form-container container">
                        <button className="close-btn" onClick={handleClose}>
                            &times;
                        </button>
                        <form id="authForm" onSubmit={handleSubmit}>
                            <h3 className="text-center" style={{ color: 'white', fontWeight: 'bold' }}>
                                Get a Free Consultation with <span style={{ color: '#fcc101' }}>BizKranti</span>
                            </h3>
                            <div className="Pop-form-group">
                                <label htmlFor="first-name">Full Name:</label>
                                <input
                                    type="text"
                                    id="first-name"
                                    name="firstName"
                                    placeholder="Enter the First Name"
                                    required
                                    autoFocus
                                />
                            </div>
                            <div className="Pop-form-group">
                                <label htmlFor="email">Email:</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    placeholder="Enter the Email"
                                    required
                                />
                                {emailError && <span className="error-message">{emailError}</span>}
                            </div>
                            <div className="Pop-form-group">
                                <label htmlFor="phone">Mobile Number:</label>
                                <input
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    value={phone}
                                    onChange={handlePhoneChange}
                                    placeholder="Enter mobile number"
                                    required
                                />
                                {phoneError && <span className="error-message">{phoneError}</span>}
                            </div>
                            <div className="Pop-form-group">
                                <label htmlFor="location">Your Company Name:</label>
                                <input
                                    type="text"
                                    id="location"
                                    name="location"
                                    placeholder="Enter your location"
                                    required
                                />
                            </div>
                            <div className="Pop-form-group">
                                <label htmlFor="options">Service you are interested in:</label>
                                <select id="options" required>
                                    <option value="">--Select an option--</option>
                                    <option value="Web Development">Web Development</option>
                                    <option value="App Development">App Development</option>
                                    <option value="UIUX Development">UIUX Development</option>
                                    <option value="SEO & Content">SEO & Content</option>
                                    <option value="Digital Marketing">Digital Marketing</option>
                                    <option value="Design Branding">Design Branding</option>
                                </select>
                            </div>
                            <div className="Pop-form-group">
                                <label htmlFor="message">Message:</label>
                                <input
                                    type="text"
                                    id="message"
                                    name="message"
                                    placeholder="Enter your message"
                                    aria-rowcount={4}
                                    required
                                />
                            </div>
                            <div className="Pop-form-group">
                                <button type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </section>
            )}
            {showThankYou && (
                <section className="Pop-ThankYou">
                    <div className="Pop-thankyou-container container">
                        <button className="close-btn" onClick={handleClose}>
                            &times;
                        </button>
                        <h3 className="text-center" style={{ color: 'red', fontWeight: 'bold' }}>
                            Thank you for your submission!
                        </h3>
                        <p className="text-center" style={{ color: 'white' }}>
                            We have received your information and will get back to you soon.
                        </p>
                    </div>
                </section>
            )}
        </>
    );
};

export default PopUp;