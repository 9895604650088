import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TogetherCreatingValue from '../jsx/TogetherCreatingValues';
import Contact from "../jsx/Contact";
import CallToAction from "../jsx/CallToAction";
import About from '../jsx/About';
import Team from '../jsx/Team';
import Bloge from "../jsx/Bloge";
import PrivacyPolicy from "../jsx/PrivacyPolicy";
import Tearms from "../jsx/Tearms";
import Refunds from "../jsx/Refunds";
import Career from "../jsx/Career";
import Form from '../jsx/Form';
import Service from "../jsx/Services";
import Appdevelopment from "../jsx/AppDevelopment";
import SeoContentWriting from "../jsx/SeoContentWriting";
import UiUxDesign from '../jsx/UiUxDesign';
import WebDevelopment from "../jsx/WebDevelopment";
import Digitalmarketing from "../jsx/DigitalMarketing";
import DesignBranding from "../jsx/DesignBranding";
const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<TogetherCreatingValue />} />
      <Route path="/CallToAction" element={<CallToAction />} />

      <Route path="/Contact" element={<Contact />} />
      <Route path="/About" element={<About />} />
      <Route path="/Team" element={<Team />} />
      <Route path="/Bloge" element={<Bloge />} />


      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      <Route path="/Tearms" element={<Tearms />} />
      <Route path="/Refunds" element={<Refunds />} />

      <Route path="/Career" element={<Career />} />
      <Route path="/Form" element={<Form />} />


      <Route path="/services" element={<Service />} />
      <Route path="/services/AppDevelopment" element={<Appdevelopment />} />
      <Route path="/services/SeoContentWriting" element={<SeoContentWriting />} />
      <Route path="/services/UiUxDesign" element={<UiUxDesign />} />
      <Route path="/services/DigitalMarketing" element={<Digitalmarketing />} />
      <Route path="/services/webDevelopment" element={<WebDevelopment />} />
      <Route path="/services/DesignBranding" element={<DesignBranding />} />

    </Routes>
  );
}

export default AppRoutes;
