import React, { useState, useEffect, useRef } from 'react';
import CallToAction from './CallToAction';
import Footer from './Footer';
import home from '../img/Career/CareerBackground.jpeg';
import side from '../img/Career/Carrer_Sec.jpeg';

import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/Career.css';

const Career = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleApplyClick = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const jobData = [
    {
      title: 'Java Developer',
      workingMode: 'Remote',
      location: 'Faizpur',
      workTime: 'Full Time',
    },
    {
      title: 'WP Developer',
      workingMode: 'Remote',
      location: 'Faizpur',
      workTime: 'Full Time',
    },
    {
      title: 'SEO Analyst',
      workingMode: 'Remote',
      location: 'Faizpur',
      workTime: 'Full Time',
    },
  ];

  const dropdownData = [
    {
      label: 'Workplace type',
      options: [
        { value: '', text: 'Workplace type' },
        { value: 'developer', text: 'Developer' },
        { value: 'designer', text: 'Designer' },
        { value: 'manager', text: 'Manager' },
        { value: 'analyst', text: 'Analyst' },
      ],
    },
    {
      label: 'Location',
      options: [
        { value: '', text: 'Location' },
        { value: 'faizpur', text: 'Faizpur' },
        { value: 'pune', text: 'Pune' },
        { value: 'mumbai', text: 'Mumbai' },
      ],
    },
    {
      label: 'Department',
      options: [
        { value: '', text: 'Department' },
        { value: 'it', text: 'IT' },
        { value: 'hr', text: 'HR' },
        { value: 'finance', text: 'Finance' },
      ],
    },
    {
      label: 'Work Type',
      options: [
        { value: '', text: 'Work Type' },
        { value: 'fulltime', text: 'Full Time' },
        { value: 'parttime', text: 'Part Time' },
      ],
    },
  ];
  const fadeInSectionRefs = useRef([]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    fadeInSectionRefs.current.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      fadeInSectionRefs.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <img src={home} alt="Home Background" className="HomeIMG" />
      <div className="HeadTitle text-dark text-center fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
        <h1>Flexibility, trust, and meaningful choice are all part of working at <br />Bizkranti.</h1>
      </div>
      <div className="MiddlePart fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
        <img src={side} alt="Side Section" className="" />
        <div>
          <p className="Para">
            Welcome to bizkranti it innovations pvt ltd. Here at Bizkranti, our mission is to shape the future of technology by focusing on innovation, teamwork, and outstanding performance. As a top-tier IT services provider,
            we take pride in offering high-quality services that lead to success for our customers. We see our employees as our most valuable resource and are dedicated to creating a supportive atmosphere where skills
            flourish and creativity is encouraged.
          </p>
          <div className="content fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
            <div className="Car-card">
              <i className="bi bi-heart"></i>
              <h2>Honesty</h2>
              <p>Putting multilingual support into practice entails</p>
            </div>
            <div className="Car-card">
              <i className="bi bi-fire"></i>
              <h2>Passion</h2>
              <p>It centres on developing a user-friendly and effective design.</p>
            </div>
            <div className="Car-card">
              <i className="bi bi-bullseye"></i>
              <h2>Impact</h2>
              <p>It guarantees that users will always have access to vital support.</p>
            </div>
            <div className="Car-card">
              <i className="bi bi-trophy"></i>
              <h2>Recognition</h2>
              <p>These sophisticated features are intended to offer users.</p>
            </div>
          </div>
        </div>
      </div>

      <section className="OurValues my-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
        <div className="container">
          <h1 className="HeadingOurValue text-dark text-center">Our <span style={{ color: '#fcc101' }}>Values</span></h1>
          <div className="row OurValuesDiv">
            <div className="col-md-3 DivContent" style={{ borderColor: '#f4B348' }}>
              <h2 style={{ color: '#f4B348' }}>Passion Of Succeed</h2>
              <p>Our enthusiasm extends beyond the things we make. The objective is to see the wider picture and alter the global environment. Creating high value and fostering long-term success is our motto.</p>
            </div>
            <div className="col-md-3 DivContent" style={{ borderColor: '#e7467e' }}>
              <h2 style={{ color: '#e7467e' }}>Do It Right</h2>
              <p>When it comes to doing things correctly, we think it's important to go above and beyond. We use common sense, practical experience, and domain knowledge while keeping an open mind to complete the task correctly and successfully.</p>
            </div>
          </div>
          <div className="row OurValuesDiv">
            <div className="col-md-3 DivContent" style={{ borderColor: '#06aed1' }}>
              <h2 style={{ color: '#06aed1' }}>Be the Best</h2>
              <p>Dedicated to Empowerment, Innovation, and Improvement. In an effort to influence the direction of technology, we continuously push ourselves to be the best at what we do.</p>
            </div>
            <div className="col-md-3 DivContent" style={{ borderColor: '#b3549d' }}>
              <h2 style={{ color: '#b3549d' }}>Be Human</h2>
              <p>Inclusion, empathy, respect for one another, and professional integrity form the foundation of our culture. Being kind can go a long way in creating lasting relationships in today's cutthroat environment full of gifted individuals.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="SelectionOptions my-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
        <div className="container">
          <h1 className="FormSectionTitle text-dark text-center">Current <span style={{ color: '#14234C' }}>Openings</span></h1>
          <div className="search-box text-center my-3">
            <i className="bi bi-search mr-2"></i>
            <input
              type="text"
              placeholder="Search Job..."
              className="form-control d-inline-block w-50"
              style={{ fontSize: '20px' }}
            />
          </div>
          <div className="DropDownSection d-flex justify-content-center flex-wrap fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
            {dropdownData.map((dropdown, index) => (
              <select
                key={index}
                className="form-control mr-3 mb-3"
                style={{ width: '200px' }}
              >
                {dropdown.options.map((option, idx) => (
                  <option key={idx} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            ))}
          </div>
          <div className="container jobs-container fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
            {jobData.map((job, index) => (
              <div className="JobsOpening text-center my-5" key={index}>
                <div className="JobTitle">
                  <h2>{job.title}</h2>
                </div>
                <div className="WorkingMode">
                  <h3>{job.workingMode}</h3>
                </div>
                <div className="Location">
                  <h3>{job.location}</h3>
                </div>
                <div className="WorkTime">
                  <h3>{job.workTime}</h3>
                </div>
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={handleApplyClick}
                >
                  Apply
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-inner">
            <h2>Please Send Your Resume On This E-mail</h2>
            {/* <p className='d-flex flex-wrap' style={{fontSize:'x-large'}}> info@bizkranti.com</p> */}
            
            <a href="mailto:info@bizkranti.com" className="d-flex flex-wrap" style={{fontSize:'x-large',color:'white'}}>
              <i className="bi bi-envelope-fill"  style={{color:'white'}}></i> info@bizkranti.com
            </a>
            <button className="btn" style={{backgroundColor:'#fcc101'}} onClick={closePopup}>
              Close
            </button>
          </div>
        </div>
      )}
      <CallToAction />
      <Footer />
    </div>
  );
};

export default Career;
