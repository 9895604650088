import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import shiv from '../img/servicesIMG/shiv.png';
import sangamner from '../img/servicesIMG/sangamner.jpeg';
import sathidar from '../img/servicesIMG/sathidar.jpg';
import Footer from './Footer';

import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/Services.css';

const ServiceCard = ({ title, description, onClick }) => (
    <button onClick={onClick} style={{ background: 'none', border: 'none' }}>
        <div className="Sercard">
            <div className="Sercontent">
                <h4 className='ser-card-title'>{title}</h4>
                <p className="Serpara" style={{ fontSize: '15px', textAlign: 'left', marginTop: '20px' }}>{description}</p>
            </div>
        </div>
    </button>
);

const services = [
    {
        title: 'Web Development',
        description: 'Building Your Computerized Nearness with Exactness and Innovation. In today’s computerized age, your site is more than just a virtual storefront—its the foundation of your online presence, a powerful communication tool, and a critical element of your business strategy.',
        onClick: (navigate) => navigate('/services/webDevelopment')
    },
    {
        title: 'App Development',
        description: 'We develop custom-made lovely and elegant websites in line with your goals that can deliver your message.',
        onClick: (navigate) => navigate('/services/AppDevelopment')
    },
    {
        title: 'UI/UX Designing',
        description: 'In today’s digital age, the first impression of a business is often made through its website. A well-designed website can captivate visitors, foster engagement, and drive conversions. This is where UI/UX (User Interface/User Experience) designing comes into play.',
        onClick: (navigate) => navigate('/services/UiUxDesign')
    },
    {
        title: 'Digital Marketing',
        description: 'In today’s fast-paced digital world, making a lasting impression online is crucial to your business\'s success. At BizKranti, we specialize in Digital Promotion Services designed to elevate your brand and drive targeted traffic to your website.',
        onClick: (navigate) => navigate('/services/DigitalMarketing')
    },
    {
        title: 'SEO & Content Writing',
        description: 'Search Engine Optimization (SEO) is the practice of optimizing your website to rank higher on search engine results pages (SERPs).',
        onClick: (navigate) => navigate('/services/SeoContentWriting')
    },
    {
        title: 'Design & Branding Services',
        description: 'In today’s competitive market, a strong brand identity is crucial for standing out and making a lasting impression.',
        onClick: (navigate) => navigate('/services/DesignBranding')
    }
];

const testimonialsData = [
    {
        name: 'SHIVVRUTTAPRIMENEW',
        image: shiv,
        text: "It's been almost a year and a half, and I'm still working with them. Thank you BizKranti for such great services and always supporting us."
    },
    {
        name: 'SANGAMNERTIMES',
        image: sangamner,
        text: "It's been almost two years and a half, and I'm still working with them. Thank you BizKranti for such great services and always supporting us."
    },
    {
        name: 'SAATHIDAAR',
        image: sathidar,
        text: "The team was responsive and skilled, making the development process smooth and hassle-free."
    }
];

const TestimonialCard = ({ name, image, text }) => (
    <div className="testimonial-box">
        <div className={name}>
            <img src={image} alt={name} />
        </div>
        <h4>{name}</h4>
        <p>"{text}"</p>
    </div>
);

const ConsoleText = ({ words = ['', ' Clients Says'], colors = ['BLACK', '#fcc101'] }) => {
    const [displayText, setDisplayText] = useState('');
    const [underscoreVisible, setUnderscoreVisible] = useState(true);
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [letterCount, setLetterCount] = useState(0);
    const [direction, setDirection] = useState(1); // 1 for forward, -1 for backward
    const textRef = useRef(null);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const currentWord = words[currentWordIndex];

            if (letterCount === currentWord.length + 1 && direction === 1) {
                setDirection(-1); // Start deleting characters
            } else if (letterCount === 0 && direction === -1) {
                setDirection(1); // Move to the next word
                setCurrentWordIndex((currentWordIndex + 1) % words.length);
            } else {
                setDisplayText(currentWord.substring(0, letterCount)); // Update the displayed text
                setLetterCount(letterCount + direction);
            }
        }, 120);

        return () => clearInterval(intervalId);
    }, [letterCount, direction, currentWordIndex, words]);

    useEffect(() => {
        const underscoreInterval = setInterval(() => {
            setUnderscoreVisible(prev => !prev);
        }, 400);

        return () => clearInterval(underscoreInterval);
    }, []);

    return (
        <h1 id="text" ref={textRef} style={{ color: colors[currentWordIndex] }}>
            {displayText}
            <span className={`console-underscore ${underscoreVisible ? '' : 'hidden'}`}>
                &#95;
            </span>
        </h1>
    );
};

const Service = () => {
    const navigate = useNavigate();
    const fadeInSectionRefs = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        fadeInSectionRefs.current.forEach((section) => {
            if (section) {
                observer.observe(section);
            }
        });

        return () => {
            fadeInSectionRefs.current.forEach((section) => {
                if (section) observer.unobserve(section);
            });
        };
    }, []);

    useEffect(() => {
        const animateCounter = () => {
            const counters = document.querySelectorAll('.counter-value');
            const speed = 5000; // The lower the speed, the faster the counter

            counters.forEach(counter => {
                const updateCount = () => {
                    const target = +counter.getAttribute('data-target');
                    const count = +counter.innerText;

                    const inc = target / speed;

                    if (count < target) {
                        counter.innerText = Math.ceil(count + inc);
                        setTimeout(updateCount, 1);
                    } else {
                        counter.innerText = target;
                    }
                };

                updateCount();
            });
        };

        animateCounter();
    }, []);

    // Scroll to top on page load
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="SERNAME fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
                <div className="SERNAME">
                    <button className="custom-buttonn">
                        <h1>OUR <span style={{ color: "#fcc101" }}>SEVICES</span></h1>
                        <span className="button-spann"></span>
                    </button>
                </div>
            </div>

            <section className="vision-mission fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
                <div className="SERbackground">
                    <div className="SERSEC2-card">
                        <h1 style={{ color: 'white' }}>
                            Kickstart <span style={{ color: '#fcc101' }}>Success Today!</span>
                        </h1>
                        <p className="SERSEC2-card-text" id="card-text">
                            Are you prepared to boost your online presence with a customized digital platform that achieves results?
                            Get in touch now to discuss your project and learn how our digital services can make a difference for your business.
                        </p>
                        <button className="SER-card-button" onClick={() => navigate('/Contact')}>
                            CONTACT WITH US
                        </button>
                    </div>
                </div>
            </section>

            <div className="d-flex justify-content-center flex-wrap gap-5 " style={{ marginTop: '80px' }} ref={(el) => fadeInSectionRefs.current.push(el)}>
                {services.slice(0, 3).map((service, index) => (
                    <ServiceCard
                        key={index}
                        title={service.title}
                        description={service.description}
                        onClick={() => service.onClick(navigate)}
                    />
                ))}
            </div>

            <div className="d-flex justify-content-center flex-wrap gap-5 mt-4" ref={(el) => fadeInSectionRefs.current.push(el)}>
                {services.slice(3).map((service, index) => (
                    <ServiceCard
                        key={index}
                        title={service.title}
                        description={service.description}
                        onClick={() => service.onClick(navigate)}
                    />
                ))}
            </div>

            <section className="testimonials fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
                <div className="testimonials-container container">
                    <h1 className="text-white justify-content-center d-flex testimonials-main-name">
                        What Our <span style={{ color: '#14243c' }}>-</span> <ConsoleText />
                    </h1>
                    <div className="testimonials-card">
                        {testimonialsData.map((testimonial, index) => (
                            <TestimonialCard
                                key={index}
                                name={testimonial.name}
                                image={testimonial.image}
                                text={testimonial.text}
                            />
                        ))}
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid SER-CallToAction text-center p-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
                    <h1 className='text-white text-center'>
                        Take the <span style={{ color: '#ffc101' }}>First Step Today!</span>
                    </h1>
                    <h4 className="text-white text-center">
                        Stay Ahead with IT Consulting Services
                        Strategize and implement the best IT solutions for your success.
                        Book a Consultation
                    </h4>
                    <button type="button" className="btn btn-warning mt-4" onClick={() => navigate('/Contact')}>
                        CONTACT WITH US
                    </button>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Service;
