// import React from "react";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import './App.css';
// import TogetherCreatingValue from './MyComponents/jsx/TogetherCreatingValues';
// import Contact from "./MyComponents/jsx/Contact";

// import Header from "./MyComponents/jsx/Header";

// import Bloge from "./MyComponents/jsx/Bloge";


// import PrivacyPolicy from "./MyComponents/jsx/PrivacyPolicy";
// import Tearms from "./MyComponents/jsx/Tearms";
// import Refunds from "./MyComponents/jsx/Refunds";


// import Career from "./MyComponents/jsx/Career";                
// import Service from "./MyComponents/jsx/Services";
// import Form from './MyComponents/jsx/Form';
// import CallToAction from "./MyComponents/jsx/CallToAction";


// import Appdevelopment from "./MyComponents/jsx/AppDevelopment";
// import DesignBranding from "./MyComponents/jsx/DesignBranding";
// import SeoContentWriting from "./MyComponents/jsx/SeoContentWriting";
// import UiUxDesign from './MyComponents/jsx/UiUxDesign';
// import WebDevelopment from "./MyComponents/jsx/WebDevelopment";
// import Digitalmarketing from "./MyComponents/jsx/DigitalMarketing";

// import About from './MyComponents/jsx/About';
// import Team from './MyComponents/jsx/Team';


// // News&Block Sub Pages
// import ClickHereAITechnology from './MyComponents/jsx/ClickHereAITechnology';
// import ClickHereDigitalMarketing from './MyComponents/jsx/ClickHereDigitalMarketing';
// import ClickHereProfWebsite from './MyComponents/jsx/ClickHereProfWebsite';
// import PopUp from "./MyComponents/jsx/PopUp";

// function App() {
//   return (
//     <>
//     <PopUp/>
//       <Router>
//         <Header />
//         <Routes>
//           <Route path="/" element={<TogetherCreatingValue />} />
//           <Route path="/Contact" element={<Contact />} />
//           <Route path="/CallToAction" element={<CallToAction />} />

//           <Route path="/About" element={<About />} />
//           <Route path="/Team" element={<Team />} />

//           <Route path="/Bloge" element={<Bloge/>}></Route>

//           <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
//           <Route path="/Tearms" element={<Tearms />} />
//           <Route path="/Refunds" element={<Refunds />} />




//           <Route path="/Career" element={<Career />} />
//           <Route path="/Form" element={<Form />} />


//           <Route path="/services" element={<Service />} />
//           <Route path="/AppDevelopment" element={<Appdevelopment />} />
//           <Route path="/SeoContentWriting" element={<SeoContentWriting />} />
//           <Route path="/UiUxDesign" element={<UiUxDesign />} />
//           <Route path="/DigitalMarketing" element={<Digitalmarketing />} />
//           <Route path="/WebDevelopment" element={<WebDevelopment />} />
//           <Route path="/DesignBranding" element={<DesignBranding />} />

//           <Route path="/ClickHereAITechnology" element={<ClickHereAITechnology />} />
//           <Route path="/ClickHereDigitalMarketing" element={<ClickHereDigitalMarketing />} />
//           <Route path="/ClickHereProfWebsite" element={<ClickHereProfWebsite />} />

//         </Routes>
//       </Router>
//     </>
//   );
// }

// export default App;


import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import Header from "./MyComponents/jsx/Header";
import PopUp from "./MyComponents/jsx/PopUp";
import AppRoutes from "./MyComponents/Route/AppRoutes";

function App() {
  return (
    <>
      <PopUp />
      <Router>
        <Header />
        <AppRoutes />
      </Router>
    </>
  );
}

export default App;

