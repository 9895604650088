// import React, { useState, useEffect, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';

// import Footer from './Footer';

// import SEO from '../img/servicesIMG/SeoContentWriting/SEO.png';
// import SeoOurServices from '../img/servicesIMG/SeoContentWriting/SeoOurServices.png';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import '../css/SeoContentWriting.css';

// const services = [
//   {
//     title: "Keyword Research",
//     description: "We conduct thorough keyword research to identify the terms and phrases your potential customers are searching for. This helps us optimize your content to target these keywords effectively.",
//   },
//   {
//     title: "On-Page SEO",
//     description: "Our team ensures that your website's pages are optimized with the right keywords, meta descriptions, headers, and internal linking structure to improve visibility and search engine rankings.",
//   },
//   {
//     title: "Content Creation",
//     description: "From blog posts and articles to product descriptions and landing pages, our skilled writers create engaging and informative content tailored to your audience's needs and interests.",
//   },
//   {
//     title: "Content Optimization",
//     description: "We review and optimize your existing content to ensure it aligns with SEO best practices and remains relevant to current trends and search algorithms.",
//   },
//   {
//     title: "Link Building",
//     description: "Building high-quality backlinks is essential for SEO success. We develop and execute link-building strategies to enhance your website's authority and credibility.",
//   },
//   {
//     title: "Content Strategy",
//     description: "We work with you to develop a comprehensive content strategy that aligns with your business goals and drives organic traffic to your site.",
//   },
// ];

// const faqItems = [
//   {
//     question: 'Why do I need quality content writing for my website?',
//     answer: 'Think of it this way. When someone just walks into your office or shop, how do you convince them to buy from you? In the digital world, quality content serves a similar purpose by engaging and convincing potential customers.',
//   },
//   {
//     question: 'Who writes content for Credible Content Writing Services?',
//     answer: 'As mentioned earlier, I handle all content personally to ensure quality and value, as it is difficult to maintain these standards when outsourcing.',
//   },
//   {
//     question: 'How do you ensure the content ranks well on Google?',
//     answer: 'I employ a range of strategies to make sure that your content is optimized for search engines. This includes keyword research, on-page optimization, and backlinking strategies.',
//   },
//   {
//     question: 'Can you handle multiple content formats?',
//     answer: 'Absolutely. Whether it is blog posts, articles, website content, or product descriptions, I can craft content in various formats to meet your needs.',
//   },
//   {
//     question: 'How often should I update my website content?',
//     answer: 'Regular updates are important to keep your content fresh and relevant. I recommend reviewing and updating your content every few months or as needed based on your business goals and industry trends.',
//   },
// ];

// const Seo = () => {
//   const [activeIndex, setActiveIndex] = useState(null);
//   const navigate = useNavigate();
//   const fadeInSectionRefs = useRef([]);

//   const handleClick = () => {
//     navigate('/Contact');
//   };

//   const toggleFAQ = (index) => {
//     setActiveIndex(activeIndex === index ? null : index);
//   };

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       (entries) => {
//         entries.forEach((entry) => {
//           if (entry.isIntersecting) {
//             entry.target.classList.add('visible');
//             observer.unobserve(entry.target);
//           }
//         });
//       },
//       { threshold: 0.1 }
//     );

//     fadeInSectionRefs.current.forEach((section) => {
//       if (section) {
//         observer.observe(section);
//       }
//     });

//     return () => {
//       fadeInSectionRefs.current.forEach((section) => {
//         if (section) observer.unobserve(section);
//       });
//     };
//   }, []);

//   useEffect(() => {
//     const animateCounter = () => {
//       const counters = document.querySelectorAll('.counter-value');
//       const speed = 5000; // The lower the speed, the faster the counter

//       counters.forEach(counter => {
//         const updateCount = () => {
//           const target = +counter.getAttribute('data-target');
//           const count = +counter.innerText;
//           const inc = target / speed;

//           if (count < target) {
//             counter.innerText = Math.ceil(count + inc);
//             setTimeout(updateCount, 1);
//           } else {
//             counter.innerText = target;
//           }
//         };

//         updateCount();
//       });
//     };

//     animateCounter();
//   }, []);

//   // Scroll to top on page load
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   return (
//     <>
//       {/* Hero Section */}
//       <section className="container-fluid my-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
//         <div className="row align-items-center">
//           <div className="col-md-6 text-center text-md-left">
//             <h1>
//               SEO And<br />
//               <span style={{ color: '#fcc101' }}>Content-Writing</span>
//             </h1>
//             <h4>Boost Your Business with SEO Excellence...</h4>
//             <button type="button" className="SeoBTN btn btn-warning" onClick={handleClick}>
//               Book a Meeting
//             </button>
//           </div>
//           <div className="col-md-6 text-center">
//             <img src={SEO} alt="A man atop an SEO sign, showcasing his proficiency in search engine optimization technologies." className="img-fluid" />
//           </div>
//         </div>
//       </section>

//       {/* Introduction Section */}
//       <section className="bg-custom-darkblue text-white text-center py-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
//         <h1>Elevate Your Online Presence with Expert SEO and Content Writing</h1>
//         <p>
//           In today's digital age, having a strong online presence is crucial for businesses of all sizes. At BizKranti, we specialize in providing top-notch SEO and content writing services to help your business shine in the crowded online marketplace.
//         </p>
//       </section>

//       {/* Why Choose Us Section */}
//       <section className="container my-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
//         <h2 className="text-primary-custom">
//           Why Choose Us for <span style={{ color: '#fcc101' }}>SEO?</span>
//         </h2>
//         <p>
//           Expertise: Our team consists of experienced SEO specialists and talented content writers who stay updated with the latest industry trends and best practices.<br />
//           Customized Solutions: We understand that every business is unique, so we tailor our services to meet your specific needs and objectives.<br />
//           Results-Driven: Our focus is on delivering measurable results that enhance your online visibility, drive traffic, and boost conversions.<br />
//           Transparency: We believe in clear communication and provide regular reports and updates on the progress of your SEO and content marketing campaigns.<br />
//           Take Your Online Presence to the Next Level: Don't let your competitors outshine you online. Partner with BizKranti for exceptional SEO and content writing services that will elevate your brand and drive meaningful results. Contact us today to learn more about how we can help your business succeed in the digital landscape.
//         </p>

//         <div className="seo row my-4" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
//           <div className="col-md-4">
//             <h3 className="text-primary-custom">
//               What is <span style={{ color: '#fcc101' }}>SEO?</span>
//             </h3>
//             <p>
//               Search Engine Optimization (SEO) is the practice of optimizing your website to rank higher on search engine results pages (SERPs). This involves various strategies, including keyword research, on-page optimization, and link building, to ensure your website is visible to potential customers searching for your products or services.
//             </p>
//           </div>
//           <div className="col-md-4">
//             <h3 className="text-primary-custom">
//               The Power of <span style={{ color: '#fcc101' }}>Quality Content</span>
//             </h3>
//             <p>
//               Content is king when it comes to engaging your audience and driving traffic to your website. High-quality, relevant, and engaging content not only attracts visitors but also keeps them on your site longer and encourages them to take action. Our content writing services are designed to create compelling content that resonates with your target audience and aligns with your brand's voice.
//             </p>
//           </div>
//         </div>
//       </section>

//       {/* Services Section */}
//       <section className="container my-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
//         <h2 className="text-primary-custom">
//           Our <span style={{ color: '#fcc101' }}>SEO and Content-Writing</span> Services
//         </h2>
//         <div className="row">
//           {services.map((service, index) => (
//             <div className="col-md-4 mb-4" key={index}>
//               <div className="seo-card">
//                 <div className="Seo-card-body">
//                   <h5 className="card-title">{service.title}</h5>
//                   <p className="card-text">{service.description}</p>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </section>



//       {/* FAQ Section */}
//       <section id="FAQ" className="seo-FAQ fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
//         <div className="seo-faq-footer">
//           <h2 className="seo-faq-header">
//             Frequently <span style={{ color: '#fcc101' }}>Asked Questions</span>
//           </h2>

//           {faqItems.map((item, index) => (
//             <div className="seo-faq-item" key={index}>
//               <button
//                 className="seo-faq-question"
//                 onClick={() => toggleFAQ(index)}
//                 aria-expanded={activeIndex === index}
//               >
//                 <span className="seo-faq-symbol-circle">
//                   <span className="faq-symbol">{activeIndex === index ? '-' : '+'}</span>
//                 </span>
//                 {item.question}
//               </button>
//               <div className={`seo-faq-answer ${activeIndex === index ? 'd-block' : 'd-none'}`}>
//                 <p>{item.answer}</p>
//               </div>
//             </div>
//           ))}
//         </div>
//       </section>

//       <section>
//         <div className="container-fluid SEO-CallToAction text-center p-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
//           <h1 className='text-white text-center'>
//             Need Engaging  <span style={{ color: '#ffc101' }}>Content?</span>
//           </h1>
//           <h4 className="text-white text-center">
//             Drive More Traffic & Sales. Invest in professional SEO and content writing services that deliver results
//             Get a Quote Today
//           </h4>
//           <button type="button" className="btn btn-warning mt-4" onClick={() => navigate('/Contact')}>
//             CONTACT WITH US
//           </button>
//         </div>
//       </section>
//       <Footer />
//     </>
//   );
// };

// export default Seo;


import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import SEO from '../img/servicesIMG/SeoContentWriting/SEO.png';
import Development from '../img/servicesIMG/SeoContentWriting/SeoOurServices.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/SeoContentWriting.css';


// images
import OnPageSeo from '../img/servicesIMG/SeoContentWriting/OnPageSeo.png';
import ContentCreation from '../img/servicesIMG/SeoContentWriting/ContentCreation.png';
import ContentOptimazation from '../img/servicesIMG/SeoContentWriting/ContentOptimazation.png';
import ContentStrategy from '../img/servicesIMG/SeoContentWriting/ContentStrategy.png';
import KeywordResearch from '../img/servicesIMG/SeoContentWriting/KeywordResearch.png';
import LinkBuilding from '../img/servicesIMG/SeoContentWriting/LinkBuilding.png';

const services = [
  {
    img: KeywordResearch,
    title: "Keyword Research",
    description: "Finding the best keywords to target for improving search rankings.",
  },
  {
    img: OnPageSeo,
    title: "On-Page SEO",
    description: "Optimizing web pages to rank better in search engines.",
  },
  {
    img: ContentCreation,
    title: "Content Creation",
    description: " Producing valuable content for your audience.",
  },
  {
    img: ContentOptimazation,
    title: "Content Optimization",
    description: "Enhancing content for better SEO performance.",
  },
  {
    img: LinkBuilding,
    title: "Link Building",
    description: "Earning quality backlinks to boost search rankings.",
  },
  {
    img: ContentStrategy,
    title: "Content Strategy",
    description: "Planning and managing content to achieve business goals",
  },
];

const faqItems = [
  { question: 'Why do I need quality content writing for my website?', answer: 'Think of it this way. When someone just walks into your office or shop, how do you convince them to buy from you? In the digital world, quality content serves a similar purpose by engaging and convincing potential customers.' },
  { question: 'Who writes content for Credible Content Writing Services?', answer: 'As mentioned earlier, we handle all content personally to ensure quality and value, as it is difficult to maintain these standards when outsourcing.' },
  { question: 'How do you ensure the content ranks well on Google?', answer: 'We employ a range of strategies to make sure that your content is optimized for search engines. This includes keyword research, on-page optimization, and backlinking strategies.' },
  { question: 'Can you handle multiple content formats?', answer: 'Absolutely. Whether it is blog posts, articles, website content, or product descriptions, We can craft content in various formats to meet your needs.' },
  { question: 'How often should I update my website content?', answer: 'Regular updates are important to keep your content fresh and relevant. We recommend reviewing and updating your content every few months or as needed based on your business goals and industry trends.' },
];

const Seo = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const navigate = useNavigate();
  const fadeInSectionRefs = useRef([]);

  const handleClick = () => {
    navigate('/Contact');
  };

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const showDetails = (serviceTitle) => {
    setSelectedService(serviceTitle);
  };

  const hideDetails = () => {
    setSelectedService(null);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    fadeInSectionRefs.current.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      fadeInSectionRefs.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Hero Section */}
      <section className="container-fluid my-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
        <div className="row align-items-center">
          <div className="col-md-6 text-center text-md-left">
            <h1>
              SEO And<br />
              <span style={{ color: '#fcc101' }}>Content-Writing</span>
            </h1>
            <h4>Boost Your Business with SEO Excellence...</h4>
            <button type="button" className="SeoBTN btn btn-warning" onClick={handleClick}>
              Book a Meeting
            </button>
          </div>
          <div className="col-md-6 text-center">
            <img src={SEO} alt="SEO" className="img-fluid" />
          </div>
        </div>
      </section>

      {/* Introduction Section */}
      <section className="bg-custom-darkblue text-white text-center py-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
        <h1>Elevate Your Online Presence with Expert SEO and Content Writing</h1>
        <p>
          In today's digital age, having a strong online presence is crucial for businesses of all sizes. At BizKranti, we specialize in providing top-notch SEO and content writing services to help your business shine in the crowded online marketplace.
        </p>
      </section>

      {/* Why Choose Us Section */}
      <section className="container my-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
        <h2 className="text-primary-custom">
          Why Choose Us for <span style={{ color: '#fcc101' }}>SEO?</span>
        </h2>
        <p>
          Expertise: Our team consists of experienced SEO specialists and talented content writers who stay updated with the latest industry trends and best practices.<br />
          Customized Solutions: We understand that every business is unique, so we tailor our services to meet your specific needs and objectives.<br />
          Results-Driven: Our focus is on delivering measurable results that enhance your online visibility, drive traffic, and boost conversions.<br />
          Transparency: We believe in clear communication and provide regular reports and updates on the progress of your SEO and content marketing campaigns.<br />
          Take Your Online Presence to the Next Level: Don't let your competitors outshine you online. 
          Partner with BizKranti for exceptional SEO and content writing services that will elevate your brand and drive meaningful results. 
          Contact us today to learn more about how we can help your business succeed in the digital landscape.
        </p>

        <div className="seo-text row ">
          <h3 className="text-primary-custom">
            What is <span style={{ color: '#fcc101' }}>SEO?</span>
          </h3>
          <p>
            Search Engine Optimization (SEO) is the practice of optimizing your website to rank higher on search engine results pages (SERPs). This involves various strategies, including keyword research, on-page optimization, and link building, to ensure your website is visible to potential customers searching for your products or services.
          </p>
          <h3 className="text-primary-custom">
            The Power of <span style={{ color: '#fcc101' }}>Quality Content</span>
          </h3>
          <p>
            Content is king when it comes to engaging your audience and driving traffic to your website. High-quality, relevant, and engaging content not only attracts visitors but also keeps them on your site longer and encourages them to take action. Our content writing services are designed to create compelling content that resonates with your target audience and aligns with your brand's voice.
          </p>
        </div>
      </section>

      {/* Services Section */}
      <section className="seo-services" id="services">
        <h1 className="text-white text-center">Our <span style={{ color: '#fcc101' }}>Services</span></h1>
        <div className="seo-container-fluid d-flex align-items-center justify-content-between py-5">
          <div className="seo-services-content">
            <div className="seo-services-image">
              <img src={Development} alt="Development" />
            </div>
            <div className="seo-service-boxes">
              {services.map((service, index) => (
                <div key={index} className="seo-service-box zoom-in" onClick={() => showDetails(service.title)}>
                  <div className="seo-services-image1">
                    <img src={service.img} alt={service.title} />
                  </div>
                  <h3 className="fs-4"><b>{service.title}</b></h3>
                  <p>{service.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {selectedService && (
        <section className="seo-details" id="details">
          <div className="seo-details-content show">
            <h2 id="details-title">{selectedService}</h2>
            <p id="details-description">
              {selectedService === 'Keyword Research' && 'We conduct thorough keyword research to identify the terms and phrases your potential customers are searching for. This helps us optimize your content to target these keywords effectively.'}
              {selectedService === 'On-Page SEO' && 'Our team ensures that your website`s pages are optimized with the right keywords, meta descriptions, headers, and internal linking structure to improve visibility and search engine rankings.'}
              {selectedService === 'Content Optimization' && 'We review and optimize your existing content to ensure it aligns with SEO best practices and remains relevant to current trends and search algorithms.'}
              {selectedService === 'Content Creation' && 'From blog posts and articles to product descriptions and landing pages, our skilled writers create engaging and informative content tailored to your audience`s needs and interests.'}
              {selectedService === 'Link Building' && 'Building high-quality backlinks is essential for SEO success. We develop and execute link-building strategies to enhance your website`s authority and credibility.'}
              {selectedService === 'Content Strategy' && 'We work with you to develop a comprehensive content strategy that aligns with your business goals and drives organic traffic to your site.'}
            </p>
            <button className="bt1" onClick={hideDetails}>Close</button>
            <button className="bt2" onClick={() => navigate('/Contact')}>Enquire Us</button>
          </div>
        </section>
      )}

      {/* FAQ Section */}
      <section id="FAQ" className="seo-FAQ fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
        <div className="seo-faq-footer">
          <h2 className="seo-faq-header">
            Frequently <span style={{ color: '#fcc101' }}>Asked Questions</span>
          </h2>
          {faqItems.map((item, index) => (
            <div className="seo-faq-item" key={index}>
              <button
                className="seo-faq-question"
                onClick={() => toggleFAQ(index)}
                aria-expanded={activeIndex === index}
              >
                <span className="seo-faq-symbol-circle">
                  <span className="faq-symbol">{activeIndex === index ? '-' : '+'}</span>
                </span>
                {item.question}
              </button>
              <div className={`seo-faq-answer ${activeIndex === index ? 'd-block' : 'd-none'}`}>
                <p>{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Call to Action Section */}
      <section>
        <div className="container-fluid SEO-CallToAction text-center p-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
          <h1 className='text-white'>
            Need Engaging <span style={{ color: '#ffc101' }}>Content?</span>
          </h1>
          <h4 className="text-white">
            Drive More Traffic & Sales. Invest in professional SEO and content writing services that deliver results. Get a Quote Today
          </h4>
          <button type="button" className="btn btn-warning mt-4" onClick={() => navigate('/Contact')}>
            CONTACT WITH US
          </button>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Seo;
