import React, { useEffect, useRef } from 'react';
import Footer from "../jsx/Footer";
import Hero from "../jsx/Hero";
import SolutionSection from "../jsx/SoluationSection";
import TechnologiesWeWork from "../jsx/TechnologiesWeWork";
import NewsBlogUpdate from '../jsx/NewsBlogUpdate';
import CallToAction from '../jsx/CallToAction';
import TogetherCreatingValue1 from '../img/TogetherCreatingValue1.jpeg';
import TogetherCreatingValue2 from '../img/TogetherCreatingValue2.jpeg';
import TogetherCreatingValue3 from '../img/TogetherCreatingValue3.jpeg';
import TogetherCreatingValue4 from '../img/TogetherCreatingValue4.jpeg';
import OVOM from './OVOM';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/TogetherCreatingValues.css';
import HeroContaint from './HeroContaint';

const TogetherCreatingValues = () => {
    const imageData = [
        {
            src: TogetherCreatingValue1,
            name: "Industries",
            links: [
                { text: "E-commerce" },
                { text: "Fintech" },
                { text: "Healthcare" },
                { text: "Media & Entertainment" },
                { text: "Education" }
            ]
        },
        {
            src: TogetherCreatingValue2,
            name: "Services",
            links: [
                {  text: "Cloud" },
                {  text: "Cyber Security" },
                {  text: "Consulting" },
                {  text: "Digital Solution" },
                {  text: "Information Technologies" }
            ]
        },
        {
            src: TogetherCreatingValue3,
            name: "Collaborative Innovation",
            links: [
                {  text: "Partnership" },
                {  text: "Co-Creation" },
                {  text: "Synergy" },
                {  text: "Client-Centric" },
                {  text: "Collaboration" }
            ]
        },
        {
            src: TogetherCreatingValue4,
            name: "Future-Proofing Business",
            links: [
                { text: "Resilience Building" },
                { text: "Long-Term Vision" },
                { text: "Emerging Trends" },
                { text: "Adaptive Strategies" },
            ]
        }
    ];

    const sectionRef = useRef(null);

    useEffect(() => {
        document.title = "Bizkranti";  // Company Name

        const section = sectionRef.current;
        const images = section.querySelectorAll('.TCV-hoverBox');

        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('TCV-animate');
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        images.forEach(image => observer.observe(image));

        return () => images.forEach(image => observer.unobserve(image));
    }, []);

        // Scroll to top on page load
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

    return (
        <>
            {/* <Hero /> */}
            <HeroContaint/>
            <section ref={sectionRef} className="TogetherCreatingValue py-5" >
                <div className="container text-center TogetherCreatingValue-MainTitle" >
                    <h1 className="display-4 mb-4">
                        <span className="text-dark">Together Creating </span>
                        <span className="text-warning">Value</span>
                    </h1>

                    <div className="row">
                        {imageData.map((item, index) => (
                            <div key={index} className="col-lg-3 col-md-4 col-sm-6 mb-4">
                                <div className="flip-card">
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front TCV-hoverBox position-relative">
                                            <img
                                                src={item.src}
                                                alt={item.name}
                                                className="img-fluid"
                                            />
                                            <h4 className="TCV-OnImgName">{item.name}</h4>
                                        </div>
                                        <div className="flip-card-back">
                                            {item.links.map((link, linkIndex) => (
                                                <a
                                                    key={linkIndex}
                                                    href={link.href}
                                                    className="mb-2"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {link.text}
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <SolutionSection />
            <OVOM />
            <TechnologiesWeWork />
            <NewsBlogUpdate />
            <CallToAction />
            <Footer />
        </>
    );
};

export default TogetherCreatingValues;
