import React, { useState, useEffect, useRef } from 'react';
import Location from '../img/contact/location.png';
import Mail from '../img/contact/mail.png';
import Call from '../img/contact/call.png';
import Fb from '../img/contact/facebook.png';
import Insta from '../img/contact/instagram.png';
import Twitter from '../img/contact/twitter.png';
import Linkin from '../img/contact/linkedin.png';

import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/Contact.css';
import Footer from './Footer';

function Contact() {
  const [selectedOption, setSelectedOption] = useState('');
  const fadeInSectionRefs = useRef([]);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted');
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    fadeInSectionRefs.current.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      fadeInSectionRefs.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  useEffect(() => {
    const animateCounter = () => {
      const counters = document.querySelectorAll('.counter-value');
      const speed = 5000; // The lower the speed, the faster the counter

      counters.forEach(counter => {
        const updateCount = () => {
          const target = +counter.getAttribute('data-target');
          const count = +counter.innerText;

          const inc = target / speed;

          if (count < target) {
            counter.innerText = Math.ceil(count + inc);
            setTimeout(updateCount, 1);
          } else {
            counter.innerText = target;
          }
        };

        updateCount();
      });
    };

    animateCounter();
  }, []);

      // Scroll to top on page load
      useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <>
      <section>
        <div className="container-fluid CONTACTNAME mb-auto mt-auto fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)} >
          <button className="CT-BTN"> <h1>Get In <span style={{ color: " #fcc101" }}> Touch </span> </h1>
            <span className="CT-BTN-SPN"></span> </button>
        </div>
        <div className='container-fluid fade-in-section' ref={(el) => fadeInSectionRefs.current.push(el)}>
          <div className='contact-main-page'>
            <div className="contact-container container">
              <div className="contactInfo text-black fs-5">
                <div>
                  <h2 className="text-center">Contact Info</h2>
                  <ul className="info">
                    <li>
                      <span className="contact-span">
                        <img src={Location} alt="location" />
                      </span>
                      <span className="contact-span">
                        K VIDYA NAGAR SUTARWADA FAIZPUR, YAWAL, JALGAON
                        <br />
                        GAT NO 922/2, SURVEY NO3782
                      </span>
                    </li>
                    <li>
                      <span className="contact-span">
                        <img src={Mail} alt="mail" />
                      </span>
                      <span className="contact-span">
                        <a href="mailto: info@bizkranti.com">info@bizkranti.com</a>
                      </span>
                    </li>
                    <li>
                      <span className="contact-span">
                        <img src={Call} alt="call" />
                      </span>
                      <span className="contact-span">7559355593</span>
                    </li>
                  </ul>
                </div>
                <ul className="sci">
                  <li>
                    <a href="https://www.facebook.com/bizkranti" target="_blank" rel="noopener noreferrer">
                      <img src={Fb} alt="Facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/bizkranti/" target="_blank" rel="noopener noreferrer">
                      <img src={Insta} alt="Instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="https://x.com/bizkranti" target="_blank" rel="noopener noreferrer">
                      <img src={Twitter} alt="Twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/bizkranti-pvt-ltd/" target="_blank" rel="noopener noreferrer">
                      <img src={Linkin} alt="LinkedIn" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="contactForm">
                <h2>Send a Message</h2>
                <form className="formBox" onSubmit={handleSubmit}>
                  <div className="inputBox w50">
                    <input type="text" name="firstName" required />
                    <span className="contact-span text-dark">First Name</span>
                  </div>
                  <div className="inputBox w50">
                    <input type="text" name="lastName" required />
                    <span className="contact-span text-dark">Last Name</span>
                  </div>
                  <div className="inputBox w50">
                    <input type="email" name="email" required />
                    <span className="contact-span text-dark">Email Address</span>
                  </div>
                  <div className="inputBox w50">
                    <input type="text" name="mobileNumber" required />
                    <span className="contact-span text-dark">Mobile Number</span>
                  </div>
                  <div className="container dropdown-container inputBox">
                    <label htmlFor="options" style={{ fontSize: '20px' }}>Choose an option :- </label>
                    <select
                      id="options"
                      value={selectedOption}
                      onChange={handleChange}
                      className="dropdown text-center " style={{
                        width: '185px',
                        height: '35px'
                      }}
                    >
                      <option value="" >Select an option</option>
                      <option value="option1">Web Development</option>
                      <option value="option2">Digital Marketing</option>
                      <option value="option3">Design&Branding</option>
                      <option value="option3">UI/UX Designing</option>
                      <option value="option3">SEO & Contain</option>
                      <option value="option3">App Development</option>
                      <option value="option4"></option>
                    </select>
                  </div>
                  <div className="inputBox w100">
                    <textarea name="message" required></textarea>
                    <span className="contact-span text-dark">Write your message here...</span>
                  </div>
                  <div className="inputBox w100">
                    <input type="submit" value="Send" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      </section>
      <Footer />
    </>
  );
}

export default Contact;