import React, { useState, useEffect } from 'react';
import '../css/Hero.css'; 
import BizSiteLogo from '../img/BizSiteLogo.png';
import HeroContaint from './HeroContaint';

function Bizkranti() {
  const [logoPosition, setLogoPosition] = useState({ top: '50%', left: '50%' });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLogoPosition({ top: '20px', left: '20px' });
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="Hero-container container">
      {/* Hero logo with dynamic position */}
      <div className="Hero-logo" style={{ top: logoPosition.top, left: logoPosition.left }}>
        <img src={BizSiteLogo} alt="Bizkranti Logo" />
      </div>
      
      {/* Hero Content */}
      <div className="Hero-content">
        <HeroContaint />
      </div>
    </div>
  );
}

export default Bizkranti;
