import React, { useEffect } from 'react';
import { useNavigate , } from 'react-router-dom';
import BizFavicon from "../img/BizFavicon.png";
import BizSiteLogo from "../img/BizSiteLogo.png";
import BizkrantiLogoCopy from "../img/BizkrantiLogoCopy.png";
import Bizkrantilogo from "../img/Bizkrantilogo.png";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

// Define the styles as a JavaScript object
const styles = {
  footer: {
    marginTop:'3vh',
    marginBottom:'auto',
    backgroundColor: 'white',
    color: 'rgb(3, 5, 71)',
    paddingTop: '20px',
    // fontSize: 'x-large', // Uncomment this line if you want to use it
  
  },
  heading: {
    color: 'black',
  },
  link: {
    border: 'none',
    background: 'right',
    textDecoration: 'none',
    color: 'inherit',
  },
  linkHover: {
    color: '#007bff',
  },
  footerBottom: {
    backgroundColor: '#ffffff',
    color: 'black',
  }
};

const Footer = () => {

  const navigate = useNavigate();
  const PrivacyPolicy = () => {
    navigate('/PrivacyPolicy');
  }
  const Tearms = () => {
    navigate('/Tearms');
  }
  const Refunds = () => {
    navigate('/Refunds');
  }
  const Career = () => {
    navigate('/Career');
  }

  // Function to handle hover state for links
  const handleMouseOver = (e) => {
    e.target.style.color = styles.linkHover.color;
  };

  const handleMouseOut = (e) => {
    e.target.style.color = styles.link.color;
  };
  
  return (
    <footer style={styles.footer} >
      <div className="container">
        <div className="row"style={{fontSize:'17px'}}>
          {/* Company Information */}
          <div className="col-lg-3 col-md-6 mb-4">
          <a href="/"><img style={{height:'50px', width:'150px'}}  src={BizkrantiLogoCopy} alt="" /></a>
            <h2 style={styles.heading}>

            </h2>
            <ul className="list-unstyled">
              <li>
                Bizkranti is a Web Design and Web Development Company in India that helps businesses to grow fast by giving
                them solutions on websites and Digital Marketing. CIN: U72900MH2020PTC351589 GST NO: 2AAJCB4945M1Z5
              </li>
            </ul>
          </div>

          {/* Contact Information */}
          <div className="col-lg-3 col-md-6 mb-4">
            <h2 style={styles.heading}>Come Meet Us</h2>
            <ul className="list-unstyled">
              <li>Email: info@bizkranti.com</li>
              <li>Phone: +91 7559355593</li>
              <li>Address: GAT NO 922/2, SURVEY NO 3780 K VIDYA NAGAR SUTARWADA FAIZPUR YAWAL JALGAON</li>
            </ul>
          </div>

          {/* Additional Links */}
          <div className="col-lg-3 col-md-6 mb-4">
            <h2 style={styles.heading}>Client Area</h2>
          <ul className="list-unstyled">
              <li>
                <button
                  style={styles.link}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                  onClick={PrivacyPolicy}
                >
                  Privacy Policy
                </button>
              </li>
              <li>
                <button
                  style={styles.link}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                  onClick={Tearms}
                >
                  Terms And Conditions
                </button>
              </li>
              <li>
                <button
                  style={{ border: 'none', background: 'right', textAlign: 'left' }}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                  onClick={Refunds}
                >
                  Refund And Cancellation Policy
                </button>
              </li>
              <li>
                <button
                  style={styles.link}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                  onClick={Career}
                >
                  Career
                </button>
              </li>
            </ul>
          </div>

          {/* Social Media Links */}
          <div className="col-lg-3 col-md-6 mb-4">
            <h2 style={styles.heading}>Follow Us</h2>
            <ul className="list-unstyled">
              <li>
                <a
                  href="https://www.facebook.com/bizkranti"
                  target="_blank"
                  style={styles.link}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                >
                  <i className="bi bi-facebook"></i> Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/bizkranti"
                  target="_blank"
                  style={styles.link}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                >
                  <i className="bi bi-twitter"></i> Twitter
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/bizkranti/"
                  target="_blank"
                  style={styles.link}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                >
                  <i className="bi bi-instagram"></i> Instagram
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/bizkranti-pvt-ltd/about/"
                  target="_blank"
                  style={styles.link}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                >
                  <i className="bi bi-linkedin"></i> LinkedIn
                </a>
              </li>
            </ul>
          </div>

        </div>
      </div>
      <div style={styles.footerBottom} className="bg-secondary text-center py-3 mt-3">
        <p className="mb-0">&copy; 2020-24 Bizkranti. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
