import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { EffectCoverflow, Pagination, Autoplay } from "swiper/modules";
import lalit from "./../img/About/Lalit.jpeg";
import rohan from "./../img/About/Rohan.jpeg";
import pratik from "./../img/About/Pratik.jpeg";
import om from "./../img/About/Om.jpeg";
import komal from "./../img/About/Komal.jpeg";
import vaibhav from "./../img/About/Vaibhav.jpeg";
import sonavne from "./../img/About/PranavSonavane.jpg";
import "../css/Team.css";

const Carrer = () => {
  return (
    <>

      <div style={{ background: '#14234c', width: '100%' }}>
      <div className="Team-header text-white mt-5" >
        <h1>Team that <span style={{color:'#fcc101'}}> Dreams, Designs, and Delivers.</span></h1>
      </div>
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          autoplay={{
            delay: 2000, // Adjust delay as needed
            disableOnInteraction: false,
          }}
          loop={true} // Ensures infinite scrolling
          pagination={{ clickable: true }}
          modules={[EffectCoverflow, Pagination, Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src={lalit} alt="Mr. Lalit" />
            <h3>Mr. Lalit Sir</h3>
            <h6>CEO & Founder</h6>
          </SwiperSlide>
          <SwiperSlide>
            <img src={komal} alt="Mr. Komal" />
            <h3>Mr. Komal</h3>
            <h6>FrontEnd Developer</h6>
          </SwiperSlide>
          <SwiperSlide>
            <img src={vaibhav} alt="Mr. Vaibhav" />
            <h3>Mr. Vaibhav</h3>
            <h6>DevOps Developer</h6>
          </SwiperSlide>
          <SwiperSlide>
            <img src={om} alt="Mr. Om" />
            <h3>Mr. Om</h3>
            <h6>Graphics Designer</h6>
          </SwiperSlide>
          <SwiperSlide>
            <img src={rohan} alt="Mr. Rohan" />
            <h3>Mr. Rohan</h3>
            <h6>FullStack Developer</h6>
          </SwiperSlide>
          <SwiperSlide>
            <img src={pratik} alt="Mr. Pratik" />
            <h3>Mr. Pratik</h3>
            <h6>Developer</h6>
          </SwiperSlide>
          <SwiperSlide>
            <img src={sonavne} alt="Mr. Pranav" />
            <h3>Mr. Pranav</h3>
            <h6>UI UX Designer</h6>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};

export default Carrer;
