import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Import images
import HomeApp from '../img/servicesIMG/AppDevelopment/HomeApp.png';
import ios from '../img/servicesIMG/AppDevelopment/ios.png';
import react from '../img/servicesIMG/AppDevelopment/react.png';
import Android from '../img/servicesIMG/AppDevelopment/Android.png';
import watch from '../img/servicesIMG/AppDevelopment/watch-f.svg';
import java from '../img/technologies/java.png';
import android from '../img/technologies/android.png';
import swift from '../img/technologies/swift.png';
import flutter from '../img/technologies/flutter.png';
import kotlin from '../img/technologies/kotlin.png';

// Import components
import CallToAction from './CallToAction';
import Footer from './Footer';

// Import styles
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/AppDevelopment.css';

function Appdevelopment() {
    const navigate = useNavigate();
    const location = useLocation();
    const fadeInSectionRefs = useRef([]);

    const [activeIndex, setActiveIndex] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    // Define technologies and FAQ items
    const technologies = [
        {
            img: android,
            title: "Android",
            description: `Android (Java/Kotlin) - Used for developing native Android applications. Dominates mobile app development for Android devices, providing deep integration with Android OS.`
        },
        {
            img: java,
            title: "Java",
            description: `Java - Back-End Development (Enterprise-Level Web Applications). Provides scalability and robustness for large-scale applications.`
        },
        {
            img: swift,
            title: "Swift",
            description: `Swift - Used for developing iOS, macOS, watchOS, and tvOS applications. Preferred for Apple ecosystem apps due to its performance, safety, and expressiveness.`
        },
        {
            img: flutter,
            title: "Flutter",
            description: `Flutter - Used for developing cross-platform mobile, web, and desktop applications. Offers a single codebase for multiple platforms, reducing development time and effort.`
        },
        {
            img: kotlin,
            title: "Kotlin",
            description: `Kotlin - Used for Android app development and can also be used for cross-platform development. Google's preferred language for Android apps due to its concise syntax, safety, and modern features.`
        },
    ];

    const faqItems = [
        { question: 'Can you build apps for both iOS and Android?', answer: 'Yes, most of the apps we make support both iOS and Android mobile platforms. We use frameworks such as React Native that enable us to support both platforms quickly.' },
        { question: 'How do you make sure you build what I want, and don’t get it wrong?', answer: 'We start by having a meeting to understand the commercial factors driving your project. This helps us align our vision with your requirements.' },
        { question: 'How do you keep my app and data secure?', answer: 'We implement various security measures to protect your data, although we do not publicly detail all our security precautions.' },
        { question: 'Can you deliver my project more quickly?', answer: 'In some cases, yes. By dedicating more resources, we can expedite the project. If you have a deadline, contact us early so we can accommodate it.' },
        { question: 'Can I make changes to my app after it’s launched?', answer: 'Yes, we recommend launching a Minimum Viable Product (MVP) first and then making incremental improvements. Our frameworks allow for easy updates.' },
    ];

    const appData = [
        { image: Android, alt: 'Android icon displaying green eyes, reflecting our dedication to utilizing advanced technologies in our projects.', title: 'Android App Development', description: 'We specialize in Android app development, operating from Dubai, the USA, and India.' },
        { image: ios, alt: 'Apple logo against a black background, showcasing the innovative technologies we leverage in our projects.', title: 'iOS App Development', description: 'We are committed to delivering top-notch results in iPhone app development.' },
        { image: react, alt: 'Depiction of the React Native symbol, emphasizing our use of this technology in developing mobile applications.', title: 'React Native Application Development', description: 'Offering services for developing React Native apps with global reputation.' },
        { image: watch, alt: 'Wearable app development', title: 'Exceptional Wearable Application Development', description: 'Get professional services for developing next-generation wearable apps.' },
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 2) % technologies.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [technologies.length]);

    const displayedItems = technologies.slice(currentIndex, currentIndex + 2);

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.replace('#', ''));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location.hash]);

    const toggleFAQ = (index) => setActiveIndex(activeIndex === index ? null : index);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        fadeInSectionRefs.current.forEach((section) => {
            if (section) {
                observer.observe(section);
            }
        });

        return () => {
            fadeInSectionRefs.current.forEach((section) => {
                if (section) observer.unobserve(section);
            });
        };
    }, []);

    useEffect(() => {
        const animateCounter = () => {
            const counters = document.querySelectorAll('.counter-value');
            const speed = 5000; // The lower the speed, the faster the counter

            counters.forEach(counter => {
                const updateCount = () => {
                    const target = +counter.getAttribute('data-target');
                    const count = +counter.innerText;

                    const inc = target / speed;

                    if (count < target) {
                        counter.innerText = Math.ceil(count + inc);
                        setTimeout(updateCount, 1);
                    } else {
                        counter.innerText = target;
                    }
                };

                updateCount();
            });
        };

        animateCounter();
    }, []);

    useEffect(() => {
        if (location.hash) {
            // Scroll to the specific section if a hash is present in the URL
            const element = document.getElementById(location.hash.replace('#', ''));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            // Scroll to the top only if there's no hash
            window.scrollTo(0, 0);
        }
    }, [location.hash]);

    return (
        <>
            <section className="container-fluid py-0 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
                <div className="row align-items-center AD-row">
                    <div className="col-md-6 py-5 ps-5">
                        <h1 className='text-dark'>Mobile App <br /> <span style={{ color: '#fcc101' }}>Development</span></h1>
                        <h4>Design compelling in-app experiences for your users using cutting-edge and intuitive UX designs.</h4>
                        <button type="button" className="AD-button btn btn-primary" onClick={() => navigate('/Contact')}>Book a Meeting</button>
                    </div>
                    <div className="col-md-6">
                        <img src={HomeApp} alt="Two people grasp a phone displaying various icons, emphasizing our engagement with contemporary technology." className="img-fluid" />
                    </div>
                </div>
            </section>

            <section className="AD-TechnologiesWeWork text-center py-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
                <div className='AD-TechnologiesWeWork-mainheader'>
                    <h1>Technologies <span style={{ color: '#fcc101' }}>We Work</span></h1>
                    <p>We are creating projects with all these technologies.</p>
                </div>
                <div className="container-fluid" id='AppTechnologies' ref={(el) => fadeInSectionRefs.current.push(el)}>
                    <div className="row">
                        {displayedItems.map((tech, index) => (
                            <div key={index} className="col-md-6 mb-4">
                                <div className="AD-TechnologiesWeWork-div">
                                    <img src={tech.img} alt={tech.title} className="AD-TechnologiesWeWork-img" />
                                    <div className="AD-TechnologiesWeWork-content">
                                        <h5>{tech.title}</h5>
                                        <p>{tech.description}</p>
                                        <button className="AD-TechnologiesWeWork-button btn btn-primary ml-auto mr-auto" onClick={() => navigate('/Bloge#section-1')}>Read More</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className="container containerr py-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
                <div className="row" style={{ width: '100%' }}>
                    <h1 style={{ color: 'white' }}>
                        Our iOS and Android app offers a unique experience for users on the go by fusing innovative features with a
                        <span style={{ color: '#fcc101' }}> user-friendly interface.</span>
                    </h1>
                    {appData.map((app, index) => (
                        <div className="col-md-6 col-lg-3 mb-4" key={index}>
                            <div className="APP-card">
                                <img src={app.image} alt={app.alt} className="card-img-top" />
                                <div className="card-body">
                                    <h5 className="card-title">{app.title}</h5>
                                    <p className="card-text">{app.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <section id="FAQ" className="AD-FAQ fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
                <div className="AD-faq-footer">
                    <h2 className="AD-faq-header">Frequently <span className="highlight-blue" style={{ color: '#fcc101' }}>Asked Questions</span></h2>
                    {faqItems.map((item, index) => (
                        <div className="AD-faq-item" key={index}>
                            <button
                                className="AD-faq-question"
                                onClick={() => toggleFAQ(index)}
                                aria-expanded={activeIndex === index}>
                                <span className="AD-faq-symbol-circle"><span className="faq-symbol">{activeIndex === index ? '-' : '+'}</span></span>
                                {item.question}
                            </button>
                            <div className={`AD-faq-answer ${activeIndex === index ? 'd-block' : 'd-none'}`}>
                                <p>{item.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <section>
                <div className="container-fluid AD-CallToAction text-center p-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
                    <h1 className='text-white text-center'>
                        Get Your App on <span style={{ color: '#ffc101' }}>the Market</span>
                    </h1>
                    <h4 className="text-white text-center">
                        Transform Your Idea into a Powerful App Start Building Your Dream App Now
                        Get a Quote Today
                    </h4>
                    <button type="button" className="btn btn-warning mt-4" onClick={() => navigate('/Contact')}>
                        CONTACT WITH US
                    </button>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Appdevelopment;
