import React, { useState } from 'react';
import '../css/Form.css';
import Footer from './Footer';

const Form = () => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Improved email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[6-9]\d{9}$/;

    let valid = true;

    // Email validation
    if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address.');
      alert('Invalid email address! Please enter a valid email address.');
      valid = false;
    } else {
      setEmailError('');
    }

    // Phone validation
    if (!phonePattern.test(phone)) {
      setPhoneError('Please enter a valid Indian mobile number.');
      alert('Invalid mobile number! Please enter a valid Indian mobile number.');
      valid = false;
    } else {
      setPhoneError('');
    }

    // If both email and phone are valid
    if (valid) {
      alert('Form submitted successfully.');
      // Proceed with form submission or AJAX request here
    }
  };

  return (
    <>
      <section className="ApplyForm">
        <div className="form-container container">
          <form id="authForm" onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="form-group">
              <label htmlFor="first-name">First Name:</label>
              <input
                type="text"
                id="first-name"
                name="firstName"
                placeholder="Enter the First Name"
                required
                autoFocus
              />
            </div>
            <div className="form-group">
              <label htmlFor="last-name">Last Name:</label>
              <input
                type="text"
                id="last-name"
                name="lastName"
                placeholder="Enter the Last Name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter the Email"
                required
              />
              {emailError && <span id="email-error">{emailError}</span>}
            </div>
            <div className="form-group">
              <label htmlFor="phone">Mobile Number:</label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={phone}
                onChange={handlePhoneChange}
                placeholder="Enter mobile number"
                required
              />
              {phoneError && <span id="error-message">{phoneError}</span>}
            </div>
            <div className="form-group">
              <label htmlFor="location">Location:</label>
              <input
                type="text"
                id="location"
                name="location"
                placeholder="Enter your location"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="file">Upload Resume:</label>
              <input
                type="file"
                id="file"
                name="file"
                accept=".pdf,.doc,.docx"
                required
              />
            </div>
            <div className="form-group">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Form;